import React, { useState } from 'react'

import IconButton from '@mui/material/IconButton'
import Box from '@material-ui/core/Box'
import Grid from '@mui/material/Grid'
import { useStyles } from './styles'
import _ from 'lodash'
import ImageIcon from '@mui/icons-material/Image'
import Badge from '@mui/material/Badge'
import Tooltip from '@mui/material/Tooltip'
import { resumeStyle } from './resumeStyle'
import { checkArrOfObjHasAtleastOneValue } from '../../utils/String'
import { Button, ImageAvatarFallback } from '../../components'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { checkUserRoleSuperUser, checkUserRoleViewer } from '../../utils/User'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import DownloadIcon from '@mui/icons-material/Download'
import { PlaylistRemove } from '@mui/icons-material'
import {
  updateParsedResumeData,
  uploadResumeImage
} from '../../store/People/Actions/UpdatePeople/UpdatePeople'
import ReactContentEditable from '../../components/ReactContentEditable'
import Button2 from '../../components/Button/Button2'
import { MoveIcon, PlusIcon, TrashIcon } from '../../components/Icons/Icons'
import MoveModal from './MoveModal'
import UploadProfileModal from './UploadProfileModal'

const ResumeViewer = ({
  selectedResumeData,
  openImage,
  s3Obj,
  selectedPeopleData = {},
  selectedResumeIndex,
  handleMasterMappingDelete,
  handleMove,
  setShowTagDrawer,
  tagState,
  handlePDFViewer,
  downloadImage,
  pageType = '',
  editMode = false,
  setEditMode = () => {}
}) => {
  const isSuperUser = checkUserRoleSuperUser()
  const [isSaving, setIsSaving] = useState(false)
  const [selectedPerson, setSelectedPerson] = useState({
    ...selectedPeopleData
  })
  const [selectedResume, setSelectedResume] = useState({
    ...selectedResumeData
  })
  const [selectedResumeParsedData, setResumeParsedData] = useState({
    ...selectedResumeData?.resume_data
  })

  const [loading, setLoading] = useState(false)
  const [moving, setMoving] = useState(false)
  const dispatch = useDispatch()

  const {
    thumbnail_url = '',
    profile_picture_location,
    screenshots = [],
    proposal_name,
    proposal_id,
    page_number,
    tags = []
  } = selectedResume

  const [profilePic, setProfilePic] = useState(profile_picture_location)
  const imagePayload = _.isEmpty(screenshots) ? thumbnail_url : screenshots
  const disabled = isSaving
  // const { leftPane, rightPane } = resumeStyle

  const classes = useStyles()

  const handleResumeOnEdit = (key, value, index, childKey) => {
    const parsedData = _.cloneDeep(selectedResumeParsedData)
    if (key === 'name') {
      key = 'emp_name'
    } else if (key === 'role') {
      key = 'emp_job_title'
      index = 0
      childKey = 'job_title'
      parsedData.emp_job_title = [
        {
          job_title: ''
        }
      ]
    }
    if (childKey) {
      parsedData[key][index][childKey] = value
    } else {
      parsedData[key] = value
    }

    setResumeParsedData({
      ...parsedData
    })
  }

  const formatResumeData = (resumeData) => {
    const profileSection = {
      profileSrc: profile_picture_location,
      name: selectedResumeParsedData?.emp_name,
      role: selectedResumeParsedData?.emp_job_title?.[0] ?? ''
    }

    return { profileSection }
  }

  const { leftPane, rightPane } = resumeStyle

  const { profileSection } = formatResumeData(selectedResumeParsedData)

  const saveResumeChanges = () => {
    setLoading(true)
    const body = {
      id: selectedResume.id,
      resume_data: selectedResumeParsedData
    }

    dispatch(
      updateParsedResumeData(body, () => {
        setLoading(false)
        setEditMode(false)
      })
    )
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const uploadProfilePic = async (data, onSuccess) => {
    const body = {
      profile_picture: {
        ...data
      },
      id: selectedResume.id
    }

    dispatch(
      updateParsedResumeData(body, (res) => {
        setProfilePic(data.src)
        onSuccess()
      })
    )
  }

  const [showMoveModal, setShowMoveModal] = useState(false)
  const [showUploadProfileModal, setShowUploadProfileModal] = useState(false)

  const valueForYears = (key, value) => {
    if (editMode || !value) {
      return value
    }

    if (key.includes('years') || key.includes('duration')) {
      if (value.toString()?.includes('years')) {
        return value
      }

      return `${value} years`
    }

    return value
  }
  return (
    <Box
      id="resume_parent"
      sx={{
        wordBreak: 'break-word',
        height: '100%'
      }}
    >
      {pageType !== 'dashboard' && pageType !== 'result' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            fontWeight: '600',
            fontSize: '16px',
            padding: '6px 10px',
            paddingTop: '3px',
            borderBottom: '1px solid #e4e4e7',
            minHeight: '32px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            {editMode ? (
              <p
                style={{
                  // position: 'absolute',
                  right: 0,
                  top: 0,
                  margin: 0,
                  fontSize: '10px',
                  background: 'rgb(106 36 245)',
                  color: 'white',
                  fontWeight: '600',
                  padding: '5px 10px',
                  borderRadius: '5px'
                }}
              >
                EDIT MODE ON
              </p>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 5
                }}
              >
                <Tooltip title="View Proposal">
                  <p
                    style={{
                      margin: '0',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                      cursor: 'pointer'
                    }}
                    onClick={() => handlePDFViewer(proposal_id, page_number)}
                  >
                    {proposal_name}
                    {isSuperUser && ' - v' + (selectedResume.version ?? '')}
                  </p>
                </Tooltip>
              </div>
            )}
            {isSaving ? (
              <IconButton>
                <CircularProgress size={24} />
              </IconButton>
            ) : editMode ? (
              <div>
                <Button
                  style={{
                    padding: '2px 10px'
                  }}
                  className={classes.tertiaryButton}
                  onClick={() => {
                    setEditMode(false)

                    setResumeParsedData({
                      ...selectedResumeData?.resume_data
                    })
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    padding: '2px 10px',
                    borderRadius: '4px',
                    backgroundColor: '#18181b',
                    color: 'white',
                    marginLeft: '5px'
                  }}
                  onClick={() => saveResumeChanges()}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{
                        color: 'white',
                        padding: '4px'
                      }}
                      size={16}
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  width: '15%'
                }}
              >
                {isSuperUser && (
                  <>
                    <Tooltip title="Edit Resume">
                      <IconButton
                        disabled={disabled}
                        onClick={() => {
                          setEditMode(!editMode)
                        }}
                      >
                        <EditIcon
                          sx={{
                            width: '16px',
                            height: '16px'
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </>
                )}

                <Tooltip title="Download Screenshot">
                  <IconButton
                    disabled={disabled}
                    onClick={() => downloadImage(imagePayload, selectedResume)}
                  >
                    <DownloadIcon
                      sx={{
                        width: '16px',
                        height: '16px'
                      }}
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Not a Resume?">
                  <IconButton
                    disabled={disabled}
                    onClick={() =>
                      handleMasterMappingDelete(
                        selectedPerson,
                        selectedResumeIndex,
                        'delete'
                      )
                    }
                  >
                    <DeleteIcon
                      sx={{
                        width: '16px',
                        height: '16px'
                      }}
                    />
                  </IconButton>
                </Tooltip>
                {selectedPerson.resumes.length > 1 && (
                  <Tooltip title="Move Resume?">
                    <IconButton
                      disabled={disabled}
                      onClick={() => {
                        setShowMoveModal(true)
                      }}
                    >
                      <MoveIcon className="size-4" strokeWidth={2} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            )}
          </Box>

          <MoveModal
            open={showMoveModal}
            setOpen={setShowMoveModal}
            s3Obj={s3Obj}
            selectedPerson={selectedPerson}
            personName={selectedResumeParsedData?.emp_name}
            onMoveToPeople={() => {
              handleMasterMappingDelete(
                selectedPerson,
                selectedResumeIndex,
                'remove'
              )
              setShowMoveModal(false)
            }}
            onMove={(destinationPeople) => {
              setMoving(true)
              handleMove(
                selectedPerson.id,
                destinationPeople,
                selectedResume.id,
                selectedResume.face_id,
                () => {
                  setShowMoveModal(false)
                }
              )
            }}
          />

          <UploadProfileModal
            open={showUploadProfileModal}
            profilePic={profilePic}
            setOpen={setShowUploadProfileModal}
            handleSaveProfilePic={uploadProfilePic}
            s3Obj={s3Obj}
          />
        </Box>
      )}
      <Box
        id="scrollableDiv"
        sx={{
          // border: editMode ? '1px solid rgb(106 36 245)' : '1px solid #d4d4d8',
          borderRadius: '4px',
          padding: editMode ? '10px' : '20px 40px',
          textAlign: 'justify',
          position: 'relative',
          height: 'calc(100% - 45px)',
          boxSizing: 'border-box',
          overflow: 'auto'
        }}
      >
        {!_.isEmpty(selectedResumeParsedData) && (
          <Grid container>
            <Grid
              item
              sm={6}
              md={4}
              lg={4}
              sx={{ padding: '10px', color: 'var(--grey-800);' }}
            >
              <div className="flex flex-col items-center">
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <Badge>
                    <ImageAvatarFallback
                      name={name}
                      profilePic={profilePic}
                      style={{
                        height: '150px',
                        width: '150px',
                        fontSize: '20px'
                      }}
                      editMode={editMode}
                      openImage={(value) => {
                        if (editMode) {
                          setShowUploadProfileModal(true)
                        } else {
                          openImage(value)
                        }
                      }}
                      s3Obj={s3Obj}
                    />
                  </Badge>
                </Box>
                <p
                  className="m-0 text-2xl font-medium text-capitalize"
                  dangerouslySetInnerHTML={{
                    __html: profileSection.name.toLowerCase()
                  }}
                />

                <p className="m-0 text-xs text-left">
                  {profileSection.role?.job_title}
                </p>
              </div>

              {Object.keys(leftPane)?.map((key, index) => {
                const {
                  label,
                  style,
                  type,
                  location,
                  children,
                  bullets,
                  placeHolder
                } = leftPane[key] || {}
                const { value: labelValue, style: labelStyle } = label || {}
                if (!leftPane[key]) return null
                if (type === 'string') {
                  const displayValue = selectedResumeParsedData[key]
                  return (
                    displayValue && (
                      <Box key={index}>
                        {label && <Box style={labelStyle}>{labelValue}</Box>}
                        <Box style={style}>
                          <ReactContentEditable
                            disabled={!editMode}
                            style={style}
                            className="contentEditableStyle"
                            html={displayValue}
                            onChange={(e) => {
                              console.log('e', key, e.currentTarget.innerText)
                              handleResumeOnEdit(key, e.currentTarget.innerText)
                            }}
                            // placeholder={placeholder}
                            // disabled={saving}
                          />
                        </Box>
                      </Box>
                    )
                  )
                } else if (type === 'array') {
                  const displayValueMap =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key] || []
                  const exists =
                    checkArrOfObjHasAtleastOneValue(displayValueMap)

                  if (bullets && exists) {
                    return (
                      <Box key={index}>
                        {label && (
                          <div
                            className="flex items-center gap-3"
                            style={{
                              marginTop: 25
                            }}
                          >
                            <Box
                              style={{
                                ...labelStyle,
                                marginTop: 0
                              }}
                              className="mt-0"
                            >
                              {labelValue}
                            </Box>
                            {editMode && (
                              <span
                                onClick={() => {
                                  const parsedData = _.cloneDeep(
                                    selectedResumeParsedData
                                  )

                                  parsedData[key].push({
                                    ...resumeSchema[key][0]
                                  })

                                  console.log('parsedData', parsedData)

                                  setResumeParsedData({
                                    ...parsedData
                                  })
                                }}
                              >
                                <PlusIcon className="size-4" strokeWidth={2} />
                              </span>
                            )}
                          </div>
                        )}
                        <ul className="list-bullets">
                          {displayValueMap &&
                            displayValueMap?.map((displayValueObj, index2) => {
                              return (
                                <li key={index2}>
                                  <Box
                                    className="parent-card"
                                    style={{
                                      position: 'relative'
                                    }}
                                  >
                                    {Object.keys(displayValueObj || {})?.map(
                                      (childKey, index3) => {
                                        const {
                                          style: childStyle,
                                          placeHolder
                                        } = children[childKey] || {}
                                        const childValue =
                                          displayValueObj[childKey] || ''

                                        if (!children[childKey]) return null

                                        return (
                                          <Box style={childStyle} key={index3}>
                                            <ReactContentEditable
                                              disabled={!editMode}
                                              style={style}
                                              className="contentEditableStyle"
                                              html={
                                                valueForYears(
                                                  childKey,
                                                  childValue
                                                ) ?? ''
                                              }
                                              onChange={(e) =>
                                                handleResumeOnEdit(
                                                  key,
                                                  e.currentTarget.innerText,
                                                  index2,
                                                  childKey
                                                )
                                              }
                                              placeholder={
                                                placeHolder ?? 'Enter Value'
                                              }
                                              // disabled={saving}
                                            />
                                          </Box>
                                        )
                                      }
                                    )}

                                    {editMode && (
                                      <TrashIcon
                                        onClick={() => {
                                          const parsedData = _.cloneDeep(
                                            selectedResumeParsedData
                                          )
                                          parsedData[key].splice(index2, 1)
                                          setResumeParsedData({
                                            ...parsedData
                                          })
                                        }}
                                        className="size-4 absolute -right-4 top-1 mt-1 ml-4 text-grey-400 card-options"
                                        strokeWidth={1.5}
                                      />
                                    )}
                                  </Box>
                                </li>
                              )
                            })}
                        </ul>
                      </Box>
                    )
                  }
                  return (
                    exists && (
                      <Box key={index}>
                        {label && <Box style={labelStyle}>{labelValue}</Box>}
                        {displayValueMap &&
                          displayValueMap?.map((displayValueObj, index2) => {
                            return (
                              <Box key={index2}>
                                {Object.keys(displayValueObj || {})?.map(
                                  (childKey, index3) => {
                                    const { style: childStyle } =
                                      children[childKey] || {}
                                    const childValue =
                                      displayValueObj[childKey] || ''
                                    return (
                                      childValue && (
                                        <Box style={childStyle} key={index3}>
                                          <ReactContentEditable
                                            disabled={!editMode}
                                            style={style}
                                            className="contentEditableStyle"
                                            html={childValue}
                                            onChange={(e) =>
                                              handleResumeOnEdit(
                                                key,
                                                e.currentTarget.innerText,
                                                index2,
                                                childKey
                                              )
                                            }
                                            // placeholder={placeholder}
                                            // disabled={saving}
                                          />
                                        </Box>
                                      )
                                    )
                                  }
                                )}
                              </Box>
                            )
                          })}
                      </Box>
                    )
                  )
                }
                return <></>
              })}
            </Grid>
            <Grid item sm={6} md={8} lg={8} sx={{ padding: '10px' }}>
              {Object.keys(rightPane)?.map((key, index) => {
                const {
                  label,
                  style,
                  type,
                  location,
                  children,
                  bullets,
                  placeHolder
                } = rightPane[key] || {}
                const { value: labelValue, style: labelStyle } = label || {}
                console.log('rightPane[key]', rightPane[key])
                if (!rightPane[key]) return null

                if (type === 'string') {
                  const displayValue = selectedResumeParsedData[key]
                  return (
                    displayValue && (
                      <Box key={index}>
                        {label && <Box style={labelStyle}>{labelValue}</Box>}
                        <Box style={style}>
                          <ReactContentEditable
                            disabled={!editMode}
                            style={style}
                            className="contentEditableStyle"
                            html={displayValue}
                            onChange={(e) => {
                              console.log('e', key, e.currentTarget.innerText)

                              handleResumeOnEdit(key, e.currentTarget.innerText)
                            }}
                            placeholder={placeHolder}
                            // disabled={saving}
                          />
                        </Box>
                      </Box>
                    )
                  )
                } else if (type === 'array') {
                  const displayValueMap =
                    location === 'people'
                      ? selectedPeopleData[key]
                      : selectedResumeParsedData[key] || []
                  const exists =
                    checkArrOfObjHasAtleastOneValue(displayValueMap)

                  if (bullets && exists) {
                    return (
                      <Box key={index}>
                        {label && (
                          <div
                            className="flex items-center gap-3"
                            style={{
                              marginTop: 25
                            }}
                          >
                            <Box
                              style={{
                                ...labelStyle,
                                marginTop: 0
                              }}
                              className="mt-0"
                            >
                              {labelValue}
                            </Box>
                            {editMode && (
                              <span
                                onClick={() => {
                                  const parsedData = _.cloneDeep(
                                    selectedResumeParsedData
                                  )

                                  parsedData[key].push({
                                    ...resumeSchema[key][0]
                                  })

                                  console.log('parsedData', parsedData)

                                  setResumeParsedData({
                                    ...parsedData
                                  })
                                }}
                              >
                                <PlusIcon className="size-4" strokeWidth={2} />
                              </span>
                            )}
                          </div>
                        )}
                        <ul className="list-bullets">
                          {displayValueMap &&
                            displayValueMap?.map((displayValueObj, index2) => {
                              return (
                                <li key={index2}>
                                  <Box
                                    className="parent-card flex flex-col"
                                    style={{
                                      position: 'relative'
                                    }}
                                  >
                                    {Object.keys(displayValueObj || {})?.map(
                                      (childKey, index3) => {
                                        const {
                                          style: childStyle,
                                          placeHolder
                                        } = children[childKey] || {}
                                        const childValue =
                                          displayValueObj[childKey] || ''

                                        if (!children[childKey]) return null

                                        return (
                                          <Box style={childStyle} key={index3}>
                                            <ReactContentEditable
                                              disabled={!editMode}
                                              style={style}
                                              className="contentEditableStyle"
                                              html={
                                                valueForYears(
                                                  childKey,
                                                  childValue
                                                ) ?? ''
                                              }
                                              onChange={(e) =>
                                                handleResumeOnEdit(
                                                  key,
                                                  e.currentTarget.innerText,
                                                  index2,
                                                  childKey
                                                )
                                              }
                                              placeholder={
                                                placeHolder ?? 'Enter Value'
                                              }
                                              // disabled={saving}
                                            />
                                          </Box>
                                        )
                                      }
                                    )}

                                    {editMode && (
                                      <TrashIcon
                                        onClick={() => {
                                          const parsedData = _.cloneDeep(
                                            selectedResumeParsedData
                                          )
                                          parsedData[key].splice(index2, 1)
                                          setResumeParsedData({
                                            ...parsedData
                                          })
                                        }}
                                        className="size-4 absolute -right-4 top-1 mt-1 ml-4 text-grey-400 card-options"
                                        strokeWidth={1.5}
                                      />
                                    )}
                                  </Box>
                                </li>
                              )
                            })}
                        </ul>
                      </Box>
                    )
                  }
                  return (
                    exists && (
                      <Box key={index}>
                        {label && <Box style={labelStyle}>{labelValue}</Box>}
                        {displayValueMap &&
                          displayValueMap?.map((displayValueObj, index2) => {
                            return (
                              <Box key={index2}>
                                {Object.keys(displayValueObj || {})?.map(
                                  (childKey, index3) => {
                                    const { style: childStyle } =
                                      children[childKey] || {}
                                    const childValue =
                                      displayValueObj[childKey] || ''
                                    return (
                                      childValue && (
                                        <Box style={childStyle} key={index3}>
                                          <ReactContentEditable
                                            disabled={!editMode}
                                            style={style}
                                            className="contentEditableStyle"
                                            html={childValue}
                                            onChange={(e) =>
                                              handleResumeOnEdit(
                                                key,
                                                e.currentTarget.innerText,
                                                index2,
                                                childKey
                                              )
                                            }
                                            placeholder={placeHolder}
                                            // disabled={saving}
                                          />
                                        </Box>
                                      )
                                    )
                                  }
                                )}
                              </Box>
                            )
                          })}
                      </Box>
                    )
                  )
                }
                return <></>
              })}
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  )
}

export default ResumeViewer

const resumeSchema = {
  emp_name: '',
  emp_professional_summary: '',
  emp_job_title: [{ job_title: '', duration: '' }],
  emp_year_experience: [{ company_name: '', years_worked: '' }],
  emp_education: [{ degree: '', university_name: '', location: '', year: '' }],
  emp_project_experience: [
    {
      project_name: '',
      project_location: '',
      project_value: '',
      project_description: '',
      project_duration: '',
      project_position: ''
    },
    {
      project_name: '',
      project_location: '',
      project_value: '',
      project_description: '',
      project_duration: ''
    },
    {
      project_name: '',
      project_location: '',
      project_value: '',
      project_description: '',
      project_duration: ''
    },
    {
      project_name: '',
      project_location: '',
      project_value: '',
      project_description: '',
      project_duration: ''
    },
    {
      project_name: '',
      project_location: '',
      project_value: '',
      project_description: '',
      project_duration: ''
    },
    {
      project_name: '',
      project_location: '',
      project_value: '',
      project_description: '',
      project_duration: ''
    },
    {
      project_name: '',
      project_location: '',
      project_value: '',
      project_description: '',
      project_duration: ''
    },
    {
      project_name: '',
      project_location: '',
      project_value: '',
      project_description: '',
      project_duration: ''
    },
    {
      project_name: '',
      project_location: '',
      project_value: '',
      project_description: '',
      project_duration: ''
    },
    {
      project_name: '',
      project_location: '',
      project_value: '',
      project_description: '',
      project_duration: ''
    }
  ],
  emp_contact_information: '',
  emp_skills: '',
  emp_certifications: [
    { name: '', description: '' },
    { name: '', description: '' },
    { name: '', description: '' }
  ],
  emp_achievements: '',
  emp_responsibilities: ''
}
