import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDocumentTagValues, updateResource } from '../../store/api'
import {
  Button,
  Loader,
  MultiSelectCheckBox,
  DateTagSelector
} from '../../components'
import { Box, IconButton, Input } from '@mui/material'
import { useStyles } from './styles'
import { toast } from 'react-toastify'
import { AddNewKey, addNewValue } from '../../sections/TagCenterSettings'
import AddIcon from '@mui/icons-material/Add'
import { useColor } from '../../ThemeContext'
import { updateNewTagValue } from '../../store/TagCenter/Actions'
import _ from 'lodash'
import ClearIcon from '@mui/icons-material/Clear'
import { renderURL } from '../../utils/TagCenter'
import { checkUserRoleViewer, hasAccess } from '../../utils/User'

const TagCenterUpdate = (props) => {
  const {
    id = '',
    document_type = '',
    filterTags = 'document',
    onUpdateCallback = () => {},
    isOnlySelect = false,
    cancelCallback = () => {},
    selectCallback = () => {}
  } = props
  const tagsCenterStateTagsAll = useSelector((state) => state?.tagCenter?.tags)
  const [tagCenterData, setTagCenterData] = useState(null)
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const [tagsCenterStateTags, setTagsCenterStateTags] = useState(null)
  const keys = Object.keys(tagsCenterStateTags || {})
  const [loadingTags, setLoadingTags] = useState({})
  const [openAddNewKey, setOpenAddNewKey] = useState(false)
  const [tagInputLoading, setTagInputLoading] = useState({})
  const dispatch = useDispatch()
  const selectWrapperRef = useRef()
  const selectRef = useRef()
  const canCreateTags = hasAccess('tags', 'create')

  const { selectedColor } = useColor()

  const isUserViewer = checkUserRoleViewer()

  const handleOpenAddNewKey = () => {
    setOpenAddNewKey(true)
  }

  const handleCloseAddNewKey = () => {
    setOpenAddNewKey(false)
  }

  useEffect(() => {
    const filteredData = Object.keys(tagsCenterStateTagsAll || {}).reduce(
      (acc, key) => {
        const { key_type } = tagsCenterStateTagsAll[key] || {}
        if (key_type && key_type?.includes(filterTags)) {
          acc[key] = tagsCenterStateTagsAll[key]
        }
        return acc
      },
      {}
    )
    setTagsCenterStateTags({ ...filteredData })
  }, [tagsCenterStateTagsAll])

  useEffect(() => {
    async function fetchData() {
      const res = await getDocumentTagValues(id, document_type)
      if (res.status === 200) {
        const tag = res.data
        const tagsValues = {}
        Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
          const { data = [], type } = tagsCenterStateTags[key] || {}
          data.forEach((element) => {
            if (tag.includes(element.id) && element.value) {
              if (tagsValues[key]) {
                tagsValues[key].push({
                  value: element.id,
                  label: element.value,
                  tag_type: type,
                  key
                })
              } else {
                tagsValues[key] = [
                  {
                    key,
                    value: element.id,
                    label: element.value,
                    tag_type: type
                  }
                ]
              }
            }
          })
        })
        setTagCenterData(tagsValues)
        setLoading(false)
      }
    }
    if (isOnlySelect) {
      setLoading(false)
    } else {
      if (
        id &&
        document_type &&
        tagsCenterStateTags !== null &&
        tagCenterData === null
      ) {
        fetchData()
      }
    }
  }, [id, document_type, isOnlySelect, tagsCenterStateTags])

  const handleTagChange = async (stateValue, key, isMulti) => {
    if (!isOnlySelect) {
      onUpdateCallback(id, 'loading', true)
      const { prevValue, value } = stateValue
      let allValues = Object.values(tagCenterData)
      let allValuesFlat = allValues.flat()
      let allValuesFlatIds = allValuesFlat.map((item) => {
        return item?.value
      })

      const req = {
        document_id: id,
        type: document_type,
        tags: allValuesFlatIds
      }
      setLoadingTags((prevLoadingTags) => ({
        ...prevLoadingTags,
        [key]: true
      }))
      const res = await updateResource(req)
      if (res.status === 200) {
        const tagsFormated = []
        Object.keys(tagCenterData || {}).forEach((key, index) => {
          const { data = [] } = tagsCenterStateTags[key] || {}
          allValues = Object.values(tagCenterData)
          allValuesFlat = allValues.flat()
          allValuesFlatIds = allValuesFlat.map((item) => {
            return item?.value
          })
          data.forEach((element) => {
            if (allValuesFlatIds.includes(element.id) && element.value) {
              tagsFormated.push({
                tag_key: key,
                tag_value: element.value,
                id: element.id
              })
            }
          })
        })
        onUpdateCallback(id, 'tags', tagsFormated)
      } else {
        toast.error('Something went wrong when updating tags.')
        setTagCenterData({
          ...tagCenterData,
          [key]: prevValue
        })
      }
      setLoadingTags((prevLoadingTags) => ({
        ...prevLoadingTags,
        [key]: false
      }))
      onUpdateCallback(id, 'loading', false)
    }
  }

  const handleOnChange = (value, key) => {
    if (_.isEmpty(value)) {
      setTagCenterData((prev) => {
        const newTagCenterData = { ...prev }
        delete newTagCenterData[key]
        return newTagCenterData
      })
    } else {
      setTagCenterData((prev) => ({
        ...prev,
        [key]: _.isArray(value) ? value : [value]
      }))
    }
  }

  const handleOnAddNew = async (value, key, isMulti) => {
    if (selectRef?.current) {
      selectRef.current.selectOption(value)
    }
    if (isMulti) {
      if (selectWrapperRef?.current) {
        selectWrapperRef.current.clearInputValue()
      }
    }
    setTimeout(() => {
      setTagInputLoading({ ...tagInputLoading, [key]: false })
    }, 300)
  }

  const handleDateChange = async (value, current_key, tag_id, tag_type) => {
    value = value.map((item) => {
      if (item instanceof Date) {
        return item.toISOString()
      }
      return item
    })
    const formatValue = []
    value.forEach((item) => {
      formatValue.push({
        value: tag_id,
        label: item,
        tag_type,
        key: current_key
      })
    })
    if (_.isEmpty(value)) {
      setTagCenterData((prev) => {
        const newTagCenterData = { ...prev }
        delete newTagCenterData[current_key]
        return newTagCenterData
      })
    } else {
      setTagCenterData((prev) => ({
        ...prev,
        [current_key]: formatValue
      }))
    }

    if (!isOnlySelect) {
      onUpdateCallback(id, 'loading', true)
      let tagCenterWithoutKey = Object.keys(tagCenterData).reduce(
        (object, key) => {
          if (key !== current_key) {
            object[key] = tagCenterData[key]
          }
          return object
        },
        {}
      )
      let allValues = Object.values(tagCenterWithoutKey)
      let allValuesFlat = allValues.flat()
      let allValuesFlatIds = allValuesFlat.forEach((item) => {
        if (item?.value) {
          return item.value
        }
      })
      const tags_date = []
      value.forEach((item) => {
        tags_date.push({
          tag_key: current_key,
          tag_value: item,
          tag_type
        })
      })
      const req = {
        document_id: id,
        type: document_type,
        tags: allValuesFlatIds,
        tags_date
      }
      setLoadingTags((prevLoadingTags) => ({
        ...prevLoadingTags,
        [current_key]: true
      }))
      const res = await updateResource(req)
      if (res.status === 200) {
        const { new_tags = [] } = res.data
        const tagsFormated = []
        const tagsCenterDataFormat = []
        tagCenterWithoutKey = Object.keys(tagCenterData || {}).reduce(
          (object, key) => {
            if (key !== current_key) {
              object[key] = tagCenterData[key]
            }
            return object
          },
          {}
        )
        allValues = Object.values(tagCenterWithoutKey)
        allValuesFlat = allValues.flat()
        allValuesFlatIds = allValuesFlat.forEach((item) => {
          if (item?.value) {
            return item.value
          }
        })
        Object.keys(tagCenterData || {}).forEach((key, index) => {
          const { data = [] } = tagsCenterStateTags[key] || {}
          data.forEach((element) => {
            if (allValuesFlatIds.includes(element.id) && element.value) {
              tagsFormated.push({
                tag_key: key,
                tag_value: element.value,
                id: element.id
              })
            }
          })
        })
        const { data = [] } = tagsCenterStateTags[current_key] || {}
        value.forEach((item) => {
          const tag = data.find((element) => {
            return element.value === item
          })
          if (tag) {
            tagsFormated.push({
              tag_key: current_key,
              tag_value: tag.value,
              id: tag.id
            })
            tagsCenterDataFormat.push({
              value: tag.id,
              label: tag.value,
              tag_type,
              key: current_key
            })
          } else {
            const newTag = new_tags.find((element) => {
              return element.value === item
            })
            if (newTag) {
              tagsFormated.push({
                tag_key: current_key,
                tag_value: newTag.value,
                id: newTag.id
              })
              tagsCenterDataFormat.push({
                value: newTag.id,
                label: newTag.value,
                tag_type,
                key: current_key
              })
            }
          }
        })

        if (_.isEmpty(value)) {
          setTagCenterData((prev) => {
            const newTagCenterData = { ...prev }
            delete newTagCenterData[current_key]
            return newTagCenterData
          })
        } else {
          setTagCenterData((prev) => ({
            ...prev,
            [current_key]: tagsCenterDataFormat
          }))
        }

        dispatch(updateNewTagValue({ new_tags, key: current_key }))
        onUpdateCallback(id, 'tags', tagsFormated)
      } else {
        toast.error('Something went wrong when updating tags')
      }
      setLoadingTags((prevLoadingTags) => ({
        ...prevLoadingTags,
        [current_key]: false
      }))
      onUpdateCallback(id, 'loading', false)
    }
  }

  return loading ? (
    <Loader loading={true} caption={''} flex />
  ) : (
    <Box className={classes.container}>
      <AddNewKey
        isOpen={openAddNewKey}
        onClose={handleCloseAddNewKey}
        source={filterTags}
      />
      {canCreateTags && (
        <Box
          sx={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '10px'
          }}
        >
          <Button
            onClick={() => handleOpenAddNewKey()}
            variant="outlined"
            sx={{
              color: '#515151',
              fontSize: '14px',
              padding: '1px',
              width: '100%'
            }}
          >
            <AddIcon />
            Add New Tag
          </Button>
        </Box>
      )}
      {keys.map((key, index) => {
        const {
          data = [],
          type,
          id,
          value_type
        } = tagsCenterStateTags[key] || {}
        const isMulti = type.includes('multi')
        const loading = tagInputLoading[key] || false
        const defaultValue = tagCenterData
          ? isMulti
            ? tagCenterData[key]
            : tagCenterData[key] && tagCenterData[key][0]
          : []
        const isDisabled = loadingTags[key] || false || isUserViewer
        const isDocumentType = value_type === 'document_type'
        const isURL = type.includes('url')
        if (
          ['multiselect', 'singleselect', 'multiurl', 'singleurl'].includes(
            type
          )
        ) {
          const options = loading
            ? []
            : data
                .filter((item) => {
                  return item.value
                })
                .map((item) => {
                  return {
                    value: item.id,
                    label: item.value
                  }
                })
          return (
            <Box key={index} className={classes.multiSelectCheckBox}>
              <MultiSelectCheckBox
                formatOptionLabel={(option) => {
                  const { __isNew__ = false } = option || {}
                  return !__isNew__ && isURL
                    ? renderURL(option.label, true)
                    : option.label
                }}
                selectRef={selectRef}
                ref={selectWrapperRef}
                isSortOptions
                isMulti={isMulti}
                isDisabled={isDisabled || loading}
                getDropdownButtonLabel={({ placeholderButtonLabel, value }) => {
                  if (!value) {
                    return (
                      <span
                        style={{
                          color: '#797979',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {placeholderButtonLabel}
                      </span>
                    )
                  }
                  const valueLables = _.isArray(value)
                    ? value?.map((item) => {
                        return item?.label
                      })
                    : [value?.label]
                  if (Array.isArray(valueLables)) {
                    if (value.length === 0) {
                      return (
                        <span
                          style={{
                            color: '#797979',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {placeholderButtonLabel}
                        </span>
                      )
                    }
                    return (
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ color: '#797979' }}>
                          {placeholderButtonLabel}:&nbsp;
                        </span>
                        <span>
                          {isURL
                            ? valueLables.map((item, index) => {
                                return renderURL(item, true, index)
                              })
                            : valueLables.join(', ')}
                        </span>
                      </span>
                    )
                  }
                  return isURL ? (
                    <Box
                      sx={{
                        whiteSpace: 'break-spaces',
                        wordBreak: 'break-all',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {renderURL(value.label, true)}
                    </Box>
                  ) : (
                    value.label
                  )
                }}
                placeholderButtonLabel={key}
                placeholder={'Select Value for ' + key}
                options={options}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                onChange={(value) => {
                  handleOnChange(value, key)
                }}
                onBlurStateChange={(stateValue) => {
                  handleTagChange(stateValue, key, isMulti)
                }}
                value={defaultValue}
                isLoading={loading}
                isCreatable={!isDocumentType && canCreateTags}
                isClearable={isOnlySelect ? true : !isDocumentType}
                onCreateOption={(value) => {
                  setTagInputLoading({ ...tagInputLoading, [key]: true })
                  const callback = (newOption) => {
                    handleOnAddNew(newOption, key, isMulti)
                  }
                  addNewValue(
                    value,
                    key,
                    type,
                    tagsCenterStateTags,
                    dispatch,
                    callback
                  )
                }}
                components={{
                  IndicatorsContainer: (props) => {
                    const { clearValue, hasValue, isDisabled, selectProps } =
                      props
                    const { isClearable } = selectProps || {}
                    return (
                      hasValue &&
                      isClearable && (
                        <Box
                          sx={{
                            '& .MuiSvgIcon-root': {
                              color: '#0645AD',
                              fontSize: '18px'
                            }
                          }}
                        >
                          <IconButton
                            disableRipple
                            onClick={clearValue}
                            disabled={isDisabled}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Box>
                      )
                    )
                  }
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: '0',
                    margin: '5px'
                  }),
                  input: (base) => ({
                    ...base,
                    fontSize: '12px',
                    padding: '0px'
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    padding: '0px 8px'
                  }),
                  placeholder: (base) => ({
                    ...base,
                    fontSize: '12px'
                  }),
                  noOptionsMessage: (base) => ({
                    ...base,
                    fontSize: '12px',
                    color: '#0645AD'
                  }),
                  singleValue: (base) => ({
                    ...base,
                    fontSize: '12px'
                  }),
                  multiValue: (base) => ({
                    ...base,
                    fontSize: '12px'
                  }),
                  loadingMessage: (base) => ({
                    ...base,
                    fontSize: '12px'
                  })
                }}
              />
            </Box>
          )
        } else {
          return (
            <Box key={index} className={classes.multiSelectCheckBox}>
              <DateTagSelector
                isMulti={isMulti}
                isDisabled={isDisabled}
                showPlaceholderOnFilled
                onChange={(value) => {
                  handleDateChange(value, key, id, type)
                }}
                value={isMulti ? defaultValue : [defaultValue]}
                placeholder={key}
              />
            </Box>
          )
        }
      })}
      {isOnlySelect && (
        <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <Button
            onClick={() => {
              selectCallback(tagCenterData)
            }}
            disabled={_.isEmpty(tagCenterData)}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              cancelCallback()
            }}
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default TagCenterUpdate
