import React, { useState, useEffect } from 'react'
import { useStyles } from './styles'
import {
  Box,
  Grid,
  Typography,
  Collapse,
  ButtonGroup,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton
} from '@mui/material'
import Button from '@mui/material/Button'
import _, { isEmpty, toInteger } from 'lodash'
import markdownit from 'markdown-it'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import {
  rfxAnalysisUpdate,
  generateCalendarEvents,
  rfxAnalysisReportUpdate
} from '../../store/api'
import { useDispatch, useSelector } from 'react-redux'
import { setToasterAlert } from '../../store/Common/Actions'
import RfxLexicalEditor from '../../containers/RfxLexicalEditor'
import EditIcon from '@mui/icons-material/Edit'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Auth } from 'aws-amplify'
import { initalizeAWSSigner } from '../../utils/AWS'
import LoadingButton from '@mui/lab/LoadingButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import Loader from '../../components/Loader'
import Checkbox from '@mui/material/Checkbox'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import ical from 'ical-generator'

const ComplianceMatrix = (props) => {
  const {
    content,
    complianceMatrix,
    setComplianceMatrix,
    id,
    setGoToPage,
    handleTextSearch,
    shareType,
    rfxName = 'Rfx',
    editingDisabled
  } = props
  const [matrixHeaders, setMatrixHeaders] = useState(
    Object.keys(complianceMatrix?.[0] || [])
  )
  const [openEdit, setOpenEdit] = useState(false)
  const [editContent, setEditContent] = useState('')
  const [isCopied, setIsCopied] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  // delete content['Compliance List'];
  const [analysisReport, setAnalysisReport] = useState(content)
  const contentLength = Object.keys(analysisReport).length
  const [activeContentEditor, setActiveContentEditor] = useState(
    Array(contentLength).fill(0)
  )
  const md = markdownit({ breaks: true, html: true })
  const [signerObj, setSignerObj] = useState({})
  const auth = useSelector((state) => state.authenticate)
  const [isRefreshingElement, setIsRefreshingElement] = useState(
    Array(contentLength).fill(false)
  )
  const [checkBoxEdit, setCheckBoxEdit] = useState(false)
  const [isSomethingRefreshing, setIsSomethingRefreshing] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [isICalLoading, setIsICalLoading] = useState(false)

  useEffect(() => {
    async function initalizeData() {
      if (auth.isAuthenticated) {
        const signer = await initalizeAWSSigner()
        setSignerObj(signer)
      } else {
        setSignerObj('')
      }
    }
    initalizeData()
  }, [])

  const convertToHTML = (markdownText) => {
    return md.render(markdownText) // this is used to convert textToHtml
  }

  const processText = (inputText) => {
    const removeDoubleLines = inputText.replace(/\n/g, ' \n\n ')
    return removeDoubleLines
  }

  const htmlToPlainText = (htmlString) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    return doc.body.textContent || ''
  }

  const handleFieldChange = (index, field, value) => {
    const updatedMatrix = [...complianceMatrix]
    updatedMatrix[index][field] = value
    setComplianceMatrix(updatedMatrix)
  }
  const [isEditing, setIsEditing] = useState(false)

  const handleFocus = () => {
    setIsEditing(true)
  }

  const handleBlur = async () => {
    if (isEditing) {
      const data = {}
      data.compliance_matrix = complianceMatrix
      data.id = id
      // const res = await rfxAnalysisUpdate(data);
      handleSaveAnalysisChanges()
      // if (res.status !== 200) {
      //   dispatch(
      //     setToasterAlert({
      //       message: "Compliance matrix not updated",
      //       show: true,
      //       type: "error",
      //     })
      //   );
      // }
    }
    setIsEditing(false)
    setCheckBoxEdit(false)
  }

  const handleReprocessSection = async (key, index) => {
    // extraction_type = [
    //   "project_summary",
    //   "project_scope",
    //   "key_dates",
    //   "evaluation_criteria",
    //   "forms_and_legal_list",
    //   "technical_proposal_outline",
    //   "potential_rfi_questions",
    //   "contact_information",
    //   "compliance_list",
    // ]
    const newArr = [...isRefreshingElement]
    newArr[index] = true
    setIsRefreshingElement(newArr)
    setIsSomethingRefreshing(true)
    const modifiedString = key.toLowerCase().replace(/\s+/g, '_')
    // const payload = {
    //   id: id,
    //   extraction_type: key,
    // }
    const data = {
      id,
      extraction_type: modifiedString,
      requestType: 'rfxrRefresh'
    }

    const url = process.env.REACT_APP_CHAT_URL || ''
    const apiUrl = new URL(url)
    const currentSession = await Auth.currentSession()
    const token = currentSession?.getAccessToken()?.getJwtToken()
    const signedUrl = await signerObj.sign({
      method: 'POST',
      hostname: apiUrl.host,
      path: apiUrl.pathname,
      body: JSON.stringify(data),
      protocol: apiUrl.protocol,
      headers: {
        'Content-Type': 'application/json',
        host: apiUrl.hostname,
        userAuth: 'Bearer ' + token
      }
    })
    const response = await fetch(`${url}`, { ...signedUrl })
    let completeText = ''
    try {
      if (response.status === 200 && response.body) {
        trackEvent(mixpanelEvents.RFX_CONTENT_REFRESHED, 'SUCCESS', {}, {})
        const reader = response.body.getReader()
        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }
          const text = new TextDecoder().decode(value)
          console.log(text)
          completeText = completeText + text
        }
        completeText = completeText.replace(/__END_OF_CHAT__/g, '')
        console.log(completeText)
        handleAnalysisReportChange(key, completeText)
      } else {
        trackEvent(mixpanelEvents.RFX_CONTENT_REFRESHED, 'FAILED', {}, {})
        console.log('Something went wrong. Extraction Failed.')
      }
    } catch (error) {
      console.log(error)
    }
    const dupArr = [...isRefreshingElement]
    dupArr[index] = false
    setIsRefreshingElement(dupArr)
    setIsSomethingRefreshing(false)
  }

  const handleDownload = () => {
    const csvRows = []

    csvRows.push(Object.keys(complianceMatrix[0]).join(','))

    complianceMatrix.forEach((item) => {
      const values = Object.values(item)?.map((value) => {
        if (typeof value === 'string') {
          // Handle newlines within cell values
          return `"${value.replace(/"/g, '""')}"`
        }
        return value
      })
      csvRows.push(values.join(','))
    })

    const csvData = csvRows.join('\n')
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = 'ComplianceMatrix.csv'
    a.click()

    URL.revokeObjectURL(url)
  }

  const handleCopyClick = (text) => {
    if (typeof text === 'object') {
      let result = ''
      text.forEach((event) => {
        result = result + event.event + ' - ' + event.event_date + '\n'
      })
      text = result
    } else {
      text = htmlToPlainText(text)
    }
    setIsCopied(true)
    navigator.clipboard.writeText(text)
    setTimeout(() => {
      setIsCopied(false)
    }, 1500)
    trackEvent(mixpanelEvents.RFX_CONTENT_COPIED, 'SUCCESS', {}, {})
    dispatch(
      setToasterAlert({
        message: 'Content Copied',
        show: true,
        type: 'success'
      })
    )
  }

  const handleEditor = (index, value) => {
    const newArr = [...activeContentEditor]
    newArr[index] = value
    setActiveContentEditor(newArr)
    setIsSomethingRefreshing(value)
  }

  const handleAnalysisReportChange = async (key, value) => {
    trackEvent(mixpanelEvents.RFX_CONTENT_EDITED, 'SUCCESS', {}, {})
    if (key === 'compliance_list') {
      setComplianceMatrix(JSON.parse(value))
      const data = {}
      data.compliance_matrix = JSON.parse(value)
      data.id = id

      const analysisReportClone = { ...analysisReport }
      analysisReportClone['Compliance List'] = JSON.parse(value)
      setAnalysisReport(analysisReportClone)
      // const res = await rfxAnalysisUpdate(data);
      // handleSaveAnalysisChanges()
    } else if (key === 'key_dates') {
      const newAnalysisReport = { ...analysisReport }
      try {
        newAnalysisReport[key] = JSON.parse(value)
        setAnalysisReport(newAnalysisReport)
      } catch (error) {
        newAnalysisReport[key] = value
        setAnalysisReport(newAnalysisReport)
      }
    } else {
      const newAnalysisReport = { ...analysisReport }
      if (newAnalysisReport[key]?.content) {
        newAnalysisReport[key].content = value
      } else {
        newAnalysisReport[key] = value
      }
      setAnalysisReport(newAnalysisReport)
    }
  }

  const handleSaveAnalysisChanges = async () => {
    if (!initialLoad) {
      const data = {}
      data.id = id
      data.rfx_analysis = analysisReport
      const res = await rfxAnalysisReportUpdate(data)
      if (res.status !== 200) {
        dispatch(
          setToasterAlert({
            message: 'Error in saving content',
            show: true,
            type: 'error'
          })
        )
      }
    }
  }

  useEffect(() => {
    handleSaveAnalysisChanges()
    setInitialLoad(false)
  }, [analysisReport])

  const sampleclick = (key = '') => {
    const modifiedString = key.toLowerCase().replace(/\s+/g, '_')
    console.log(modifiedString)
  }

  const handleCheckboxToggle = async (index) => {
    const updatedMatrix = [...complianceMatrix]
    const updatedEntry = {
      ...updatedMatrix[index],
      checkbox: updatedMatrix[index]?.checkbox
        ? !updatedMatrix[index]?.checkbox
        : true
    }
    updatedMatrix[index] = updatedEntry
    setComplianceMatrix(updatedMatrix)
    const data = {}
    data.compliance_matrix = updatedMatrix
    data.id = id
    const analysisReportClone = { ...analysisReport }
    analysisReportClone['Compliance List'] = updatedMatrix
    setAnalysisReport(analysisReportClone)
    // handleSaveAnalysisChanges()
    // const res = await rfxAnalysisUpdate(data);
    // if (res.status !== 200) {
    //   dispatch(
    //     setToasterAlert({
    //       message: "Compliance matrix not updated",
    //       show: true,
    //       type: "error",
    //     })
    //   );
    // }
  }

  const checkIfPageNumberValid = (pageNumber) => {
    if (pageNumber === parseInt(pageNumber) && pageNumber > 0) {
      return true
    }
    return false
  }

  const handleDownloadICal = async () => {
    setIsICalLoading(true)
    const data = {
      id,
      events: analysisReport?.key_dates || ''
    }
    const res = await generateCalendarEvents(data)

    if (res.status === 200) {
      const response = res?.data?.data
      const cal = ical({ name: 'My Calendar' })

      response.forEach((event) => {
        try {
          const startDate = new Date(event.startDateTime)
          const endDate = event?.endDateTime
            ? new Date(event.endDateTime)
            : new Date(event.startDateTime)

          if (!isNaN(startDate) && !isNaN(endDate)) {
            const eve = {
              start: startDate,
              end: endDate,
              summary: event?.name,
              description: event?.description,
              location: event?.location
            }
            if (event?.organizer) {
              eve.organizer = {
                name: event?.organizer,
                email: event?.organizerEmail
              }
            }
            cal.createEvent(eve)
          } else {
            console.error('Invalid date:', event)
          }
        } catch (error) {
          dispatch(
            setToasterAlert({
              message: 'Error in generating iCal',
              show: true,
              type: 'error'
            })
          )
        }
      })
      const icalString = cal.toString()
      const blob = new Blob([icalString], {
        type: 'text/calendar;charset=utf-8'
      })

      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      // a.download = 'calendar.ics';
      a.download = `${rfxName}_Calendar.ics`
      document.body.appendChild(a)
      a.click()

      // Cleanup
      setTimeout(() => {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }, 0)
    } else {
      dispatch(
        setToasterAlert({
          message: 'Error in generating iCal',
          show: true,
          type: 'error'
        })
      )
    }
    setIsICalLoading(false)
  }

  const convertDate = (dateStr) => {
    const date = new Date(dateStr)

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    }

    const readableDate = date.toLocaleDateString('en-US', options)
    return readableDate
  }

  const handleDownloadICalIndividualRow = async (event) => {
    const cal = ical({ name: 'My Calendar' })
    let calenderEvent

    try {
      const startDate = new Date(event.startDateTime)
      const endDate = event?.endDateTime
        ? new Date(event.endDateTime)
        : new Date(event.startDateTime)

      if (!isNaN(startDate) && !isNaN(endDate)) {
        const eve = {
          start: startDate,
          end: endDate,
          summary: event?.name,
          description: event?.description,
          location: event?.location
        }
        if (event?.organizer) {
          eve.organizer = {
            name: event?.organizer,
            email: event?.organizerEmail
          }
        }
        cal.createEvent(eve)
      } else {
        console.error('Invalid date:', event)
      }
    } catch (error) {
      console.log(error)
      dispatch(
        setToasterAlert({
          message: 'Error in generating iCal',
          show: true,
          type: 'error'
        })
      )
    }
    const icalString = cal.toString()
    const blob = new Blob([icalString], { type: 'text/calendar;charset=utf-8' })

    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    // a.download = 'calendar.ics';
    a.download = `${rfxName}_Calendar.ics`
    document.body.appendChild(a)
    a.click()

    // Cleanup
    setTimeout(() => {
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }, 0)
  }

  return (
    <div style={{ padding: '14px' }}>
      {!isEmpty(analysisReport) &&
        Object.entries(analysisReport)?.map(([key, value], index) => {
          if (!value) return <></>
          if (key === 'Compliance List') return <></>
          if (key === 'hidden') return <></>
          if (Array.isArray(value)) {
            return (
              <Accordion
                key={index}
                defaultExpanded={true}
                square={true}
                style={{
                  marginBottom: '16px',
                  backgroundColor: '#f0f3f9',
                  border: '1px solid #ccc'
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{}}>
                  <Box
                    sx={{
                      fontWeight: 'bold',
                      marginRight: '8px',
                      alignSelf: 'center'
                    }}
                    style={{}}
                  >
                    {_.startCase(key)}
                  </Box>
                  <Box
                    sx={{
                      fontWeight: 'bold',
                      position: 'absolute',
                      right: '40px',
                      alignSelf: 'center'
                    }}
                  >
                    {shareType !== 'public' && (
                      <Tooltip title="Refresh">
                        <IconButton
                          disabled={isSomethingRefreshing}
                          id="refresh-content"
                          disableRipple
                          onClick={(e) => {
                            e.stopPropagation() // Prevent the Accordion from collapsing
                            handleReprocessSection(key, index)
                          }}
                        >
                          {isRefreshingElement[index] ? (
                            <LoadingButton
                              loading={isRefreshingElement[index]}
                              style={{ fontSize: '17px' }}
                            />
                          ) : (
                            <RefreshIcon style={{ fontSize: '17px' }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Copy">
                      <IconButton
                        id="copy-content"
                        disableRipple
                        onClick={(e) => {
                          e.stopPropagation()
                          handleCopyClick(value)
                        }}
                      >
                        <ContentCopyIcon style={{ fontSize: '17px' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  style={{ paddingTop: '0px', display: 'block' }}
                >
                  {isRefreshingElement[index] ? (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '10px'
                      }}
                    >
                      <Loader
                        loading={true}
                        caption={'Refreshing the content'}
                      />
                    </Box>
                  ) : (
                    <>
                      <TableContainer>
                        <Table
                          sx={{ minWidth: 100 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Event</TableCell>
                              <TableCell>Date</TableCell>
                              <TableCell align="right">Download</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {value.map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell>
                                  {row?.timingDescription &&
                                  row?.timingDescription !== ''
                                    ? row?.timingDescription
                                    : convertDate(row.startDateTime)}
                                </TableCell>
                                <TableCell align="right">
                                  <Tooltip title="Download ics">
                                    <IconButton
                                      onClick={() =>
                                        handleDownloadICalIndividualRow(row)
                                      }
                                    >
                                      <CloudDownloadIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            )
          }
          if (value) {
            if (value?.content) {
              value = value.content
            }

            value = convertToHTML(value)
            return (
              <Accordion
                key={index}
                defaultExpanded={true}
                square={true}
                style={{
                  marginBottom: '16px',
                  backgroundColor: '#f0f3f9',
                  border: '1px solid #ccc'
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{}}>
                  <Box
                    sx={{
                      fontWeight: 'bold',
                      marginRight: '8px',
                      alignSelf: 'center'
                    }}
                    style={{}}
                  >
                    {_.startCase(key)}
                  </Box>
                  <Box
                    sx={{
                      fontWeight: 'bold',
                      position: 'absolute',
                      right: '40px',
                      alignSelf: 'center'
                    }}
                  >
                    {key === 'key_dates' && (
                      <>
                        {isICalLoading ? (
                          <LoadingButton
                            loading={isICalLoading}
                            style={{ fontSize: '17px' }}
                          />
                        ) : (
                          <Tooltip title="Download ics">
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation()
                                handleDownloadICal()
                              }}
                            >
                              <CloudDownloadIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                    {shareType !== 'public' && (
                      <Tooltip title="Refresh">
                        <IconButton
                          disabled={isSomethingRefreshing}
                          id="refresh-content"
                          disableRipple
                          onClick={(e) => {
                            e.stopPropagation() // Prevent the Accordion from collapsing
                            handleReprocessSection(key, index)
                          }}
                        >
                          {isRefreshingElement[index] ? (
                            <LoadingButton
                              loading={isRefreshingElement[index]}
                              style={{ fontSize: '17px' }}
                            />
                          ) : (
                            <RefreshIcon style={{ fontSize: '17px' }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                    {shareType !== 'public' && (
                      <Tooltip title="Edit">
                        <IconButton
                          disabled={isSomethingRefreshing || editingDisabled}
                          id="edit-content"
                          disableRipple
                          onClick={(e) => {
                            e.stopPropagation()
                            handleEditor(index, 1)
                          }}
                        >
                          <EditIcon style={{ fontSize: '17px' }} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Copy">
                      <IconButton
                        id="copy-content"
                        disableRipple
                        onClick={(e) => {
                          e.stopPropagation()
                          handleCopyClick(value)
                        }}
                      >
                        <ContentCopyIcon style={{ fontSize: '17px' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  style={{ paddingTop: '0px', display: 'block' }}
                >
                  {activeContentEditor[index] ? (
                    <Box
                      sx={{
                        width: '100%',
                        minHeight: '300px',
                        background: 'white'
                      }}
                    >
                      <RfxLexicalEditor
                        onClose={() => handleEditor(index, 0)}
                        htmlString={value}
                        metaData={{ key }}
                        handleAnalysisReportChange={handleAnalysisReportChange}
                      />
                    </Box>
                  ) : (
                    <>
                      {isRefreshingElement[index] ? (
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '10px'
                          }}
                        >
                          <Loader
                            loading={true}
                            caption={'Refreshing the content'}
                          />
                        </Box>
                      ) : (
                        <Box
                          dangerouslySetInnerHTML={{ __html: value }}
                          sx={{
                            '& > *': { marginTop: '0px' },
                            lineHeight: '1.5'
                          }}
                        ></Box>
                      )}
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            )
          } else {
            return <></>
          }
        })}
      {complianceMatrix?.length > 0 && (
        <Accordion
          defaultExpanded={true}
          square={true}
          style={{
            marginBottom: '16px',
            backgroundColor: '#f0f3f9',
            border: '1px solid #ccc'
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ marginBottom: '0px' }}
          >
            <Box
              sx={{
                fontWeight: 'bold',
                marginRight: '8px',
                alignSelf: 'center'
              }}
              style={{}}
            >
              Compliance Matrix
            </Box>
            <Box
              sx={{
                fontWeight: 'bold',
                position: 'absolute',
                right: '40px',
                alignSelf: 'center'
              }}
            >
              {shareType !== 'public' && (
                <Tooltip title="Refresh">
                  <IconButton
                    disabled={isSomethingRefreshing}
                    id="refresh-content"
                    disableRipple
                    onClick={(e) => {
                      e.stopPropagation()
                      handleReprocessSection(
                        'compliance_list',
                        contentLength - 1
                      )
                    }}
                  >
                    {isRefreshingElement[contentLength - 1] ? (
                      <LoadingButton
                        loading={isRefreshingElement[contentLength - 1]}
                        style={{ fontSize: '17px' }}
                      />
                    ) : (
                      <RefreshIcon style={{ fontSize: '17px' }} />
                    )}
                  </IconButton>
                </Tooltip>
              )}
              {/* <Button variant="contained" startIcon={<CloudDownloadIcon />} onClick={handleDownload}>
                Download CSV
              </Button> */}
              <Tooltip title="Download CSV">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation()
                    handleDownload()
                  }}
                >
                  <CloudDownloadIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            style={{ paddingTop: '0px', marginTop: '0px', display: 'block' }}
          >
            <Box style={{ display: 'block', width: '100%' }}>
              {complianceMatrix?.map((item, index) => (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingBottom: '14px'
                    }}
                  >
                    <Checkbox
                      checked={item?.checkbox ? item?.checkbox : false} // Replace with the actual value you want to bind
                      onChange={() => handleCheckboxToggle(index)}
                    />
                    <div style={{ marginLeft: '8px', flex: 1 }}>
                      <TextField
                        value={item?.Compliance}
                        onChange={(e) =>
                          handleFieldChange(index, 'Compliance', e.target.value)
                        }
                        multiline={true}
                        fullWidth
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        InputProps={{
                          readOnly: shareType === 'public'
                        }}
                      />
                      {checkIfPageNumberValid(item?.['Page Number']) && (
                        <span
                          color="primary"
                          onClick={() => setGoToPage(item?.['Page Number'])}
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline'
                          }}
                        >
                          Pg {item?.['Page Number']}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  )
}

export default ComplianceMatrix
