import React, { useEffect, useState, useRef } from 'react'
import { useStyles } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import _, { set } from 'lodash'
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
  useLocation
} from 'react-router-dom'
import { ROUTES } from '../../config/routes'
import {
  AutoCompleteSearch,
  TagFilter,
  Tabs,
  Loader,
  Button,
  Container,
  ImageGallery
} from '../../components'
import { Grid, Box, Tooltip, Dialog, IconButton } from '@mui/material'
import { initalizeS3, checkExpiry } from '../../utils/AWS'
import {
  checkUserRoleSuperUser,
  checkUserRoleAdmin,
  checkUserRoleAnnotator,
  checkDomainAccess,
  userIdToName
} from '../../utils/User'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useColor } from '../../ThemeContext'
import { getParticularMasterPeople, searchApi } from '../../store/api'
import { ADD_RECENT_SEARCH } from '../../store/actionTypes'
import {
  processSearchData,
  addScreenshotsToSections,
  addVariationsToKnowledges
} from './searchHelper'
import InfiniteScroll from 'react-infinite-scroll-component'
import Result from '../../sections/DashboardResult'
import JustifiedLayout from '@codekraft-studio/react-justified-layout'
import ResumeResult from '../../sections/ResumeResult'
import ResourceFileView from '../../sections/ResourceFileView'
import { copyHTMLContent } from '../../utils/CopyHTML'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import { formatResponse } from '../../store/People/Actions/GetPeople/GetPeople'

const DashboardResult = (props) => {
  const { parent = 'dashboard' } = props
  const isDashboard = parent === 'dashboard'
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const keywordParams = searchParams.get('keyword')
    ? JSON.parse(searchParams.get('keyword'))
    : ''
  const contentIdParams = searchParams.get('content_id')
  const peopleIdParams = searchParams.get('people')
  const isSuperUser = checkUserRoleSuperUser()
  const isAdmin = checkUserRoleAdmin()
  const isAnnotator = checkUserRoleAnnotator()
  const processPeople = checkDomainAccess('people_resume')
  const processClients = checkDomainAccess('client_resume')
  const processProjects = checkDomainAccess('project_resume')
  const tabs = [
    {
      label: 'All',
      key: 'all',
      children: <></>
    },
    {
      label: 'Documents',
      key: 'document',
      children: <></>
    },
    {
      label: 'Knowledge',
      key: 'knowledge',
      children: <></>
    },
    {
      label: 'Assets',
      key: 'asset',
      children: <></>
    }
  ]

  processPeople &&
    tabs.push({
      label: 'People',
      key: 'people',
      children: <></>
    })
  // processClients && tabs.push({
  //   label: "Clients",
  //   key: 'client',
  //   children: <></>
  // })
  processProjects &&
    tabs.push({
      label: 'Projects',
      key: 'project',
      children: <></>
    })

  const location = useLocation()
  const { searchType: searchTypeParams } = location.state || {
    searchType: 'all'
  }
  const { DASHBOARD_RESULT } = ROUTES
  const classes = useStyles()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { domain_id = '' } = domain || {}
  const [searchLoading, setSearchLoading] = useState(true)
  const [searchLoadingPage, setSearchLoadingPage] = useState({})
  const [searchKey, setSearchKey] = useState(keywordParams)
  const [searchType, setSearchType] = useState('all')
  const searchTypeRef = useRef(searchType)
  const [s3Obj, sets3Obj] = useState({})
  const [activeTab, setActiveTab] = useState(null)
  const [tabOptions, setTabOptions] = useState(tabs)
  const [error, setError] = useState(null)
  const [page, setPage] = useState(1)
  const [hasMoreData, setHasMoreData] = useState({})
  const [searchResultsAll, setSearchResultsAll] = useState({})
  const [searchResultsObj, setSearchResultsObj] = useState({})
  const [resultCount, setResultCount] = useState({})
  const [dataLength, setDataLength] = useState(0)
  const resultCountRef = useRef({})
  const abortController = useRef([])
  const scrollId = 'scrollableDashboardDiv'
  const searchResultsAllRef = useRef(searchResultsAll)
  const searchResultsObjRef = useRef(searchResultsObj)
  const [imageGallery, setImageGallery] = useState([])
  const [imageGalleryIndex, setImageGalleryIndex] = useState(0)
  const [selectedFile, setSelectedFile] = useState(null)
  const [redirectTab, setRedirectTab] = useState(searchTypeParams)
  const redirectTabRef = useRef(redirectTab)
  const [selectedTags, setSelectedTags] = useState(null)
  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)
  const [tagsOptions, setTagsOptions] = useState(null)
  const [filterMetaData, setFilterMetaData] = useState({})
  const [searchData, setSearchData] = useState({})
  const [scrollTabMap, setScrollTabMap] = useState({})
  const [loadedInitalQuery, setLoadedInitalQuery] = useState(!searchTypeParams)
  const [documentTagCenter, setDocumentTagCenter] = useState(null)
  const [resourceLoading, setResourceLoading] = useState(false)

  useEffect(() => {
    const tagsValues = {}
    let documentTags = []
    Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
      const {
        data = [],
        type,
        value_type,
        ...rest
      } = tagsCenterStateTags[key] || {}
      if (value_type?.includes('document_type')) {
        documentTags = data
      }
      if (!type.includes('date')) {
        data.forEach((element) => {
          if (element.value) {
            if (tagsValues[key]?.values) {
              tagsValues[key].values.push({
                value: element.id,
                label: element.value
              })
            } else {
              if (!tagsValues[key]) tagsValues[key] = {}
              tagsValues[key].values = [
                {
                  value: element.id,
                  label: element.value
                }
              ]
            }
          }
          tagsValues[key] = {
            ...tagsValues[key],
            type,
            value_type,
            ...rest
          }
        })
      }
    })
    setTagsOptions(tagsValues)
    setDocumentTagCenter(documentTags)
  }, [tagsCenterStateTags])

  const findDocumentTypeTag = (result) => {
    const { tag_center = [], result_type } = result
    let fallbackTag = result_type
    if (result_type === 'section') {
      fallbackTag = result?.document_id ? 'Document' : 'Proposal'
      if (documentTagCenter) {
        fallbackTag = _.get(
          _.find(documentTagCenter, (obj) => _.includes(tag_center, obj.id)),
          'value',
          fallbackTag
        )
      }
    } else if (result_type === 'narrative') {
      fallbackTag = 'Knowledge'
    }
    return fallbackTag
  }

  const handleTagsValueChange = (key, value, mode) => {
    if (mode === 'value') {
      if (_.isEmpty(value)) {
        const temp = { ...selectedTags }
        delete temp[key]
        setSelectedTags(temp)
      } else {
        if (selectedTags) {
          setSelectedTags({
            ...selectedTags,
            [key]: {
              ...selectedTags[key],
              values: value
            }
          })
        } else {
          setSelectedTags({
            [key]: {
              values: value
            }
          })
        }
      }
    }
    if (mode === 'condition') {
      if (selectedTags) {
        setSelectedTags({
          ...selectedTags,
          [key]: {
            ...selectedTags[key],
            condition: value
          }
        })
      } else {
        setSelectedTags({
          [key]: {
            condition: value
          }
        })
      }
    }
  }

  useEffect(() => {
    redirectTabRef.current = redirectTab
  }, [redirectTab])

  useEffect(() => {
    searchResultsAllRef.current = searchResultsAll
  }, [searchResultsAll])

  useEffect(() => {
    searchResultsObjRef.current = searchResultsObj
  }, [searchResultsObj])

  const hasVerticalScrollbar = (elementId) => {
    const element = document.getElementById(elementId)
    if (!element) {
      return false
    }
    const hasScrollbar = element.scrollHeight > element.clientHeight
    return hasScrollbar
  }

  const increaseDataLength = () => {
    setDataLength(dataLength + 1)
  }

  useEffect(() => {
    searchTypeRef.current = searchType
  }, [searchType])

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
    return () => {
      clearControllers()
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(s3Obj)) {
      if (keywordParams) {
        handleSearch()
      } else {
        setSearchLoading(false)
      }
    }
  }, [s3Obj])

  const addSearchParams = () => {
    if (isDashboard) {
      let keyword = ''
      if (searchKey) {
        keyword = JSON.stringify(searchKey)
      }
      if (searchKey) {
        navigate({
          pathname: DASHBOARD_RESULT,
          search: createSearchParams({
            keyword,
            ...(contentIdParams ? { content_id: contentIdParams } : {}),
            ...(peopleIdParams ? { people: peopleIdParams } : {})
          }).toString()
        })
      } else {
        navigate({
          pathname: DASHBOARD_RESULT
        })
      }
    }
  }

  const processFilterMeta = (filterMeta) => {
    const newMetaData = _.cloneDeep(filterMetaData)
    Object.keys(filterMeta).forEach((key, index) => {
      const values = _.compact(filterMeta[key]?.values) || []
      const currentValues = newMetaData[key]?.rawValues || []
      if (key === 'source_document' || key === 'knowledge_collection') {
        const combinedValues = [...currentValues, ...values]
        const uniqueValues = _.uniqBy(combinedValues, 'value')
        if (_.isEmpty(uniqueValues)) {
          delete newMetaData[key]
        } else {
          newMetaData[key] = {
            ...newMetaData[key],
            values: uniqueValues,
            type: 'default',
            editable: 'default',
            rawValues: uniqueValues
          }
        }
      } else {
        const combinedValues = [...currentValues, ...values]
        const uniqueValues = _.uniq(combinedValues)
        const uniqueObj = uniqueValues.map((item) => ({
          value: item,
          label: key === 'created_by' ? userIdToName(item, domain) : item
        }))
        if (_.isEmpty(uniqueObj)) {
          delete newMetaData[key]
        } else {
          newMetaData[key] = {
            ...newMetaData[key],
            values: uniqueObj,
            type: 'default_over',
            editable: 'default',
            rawValues: uniqueValues
          }
        }
      }
    })
    setFilterMetaData(newMetaData)
  }

  const removeDuplicates = (processedData, page, count) => {
    return new Promise((resolve, reject) => {
      try {
        const { resultsets, resumesets, filterMeta = {} } = processedData
        processFilterMeta(filterMeta)
        const searchType = searchTypeRef.current
        const searchResultsObj = searchResultsObjRef.current
        const {
          results = [],
          resumes = [],
          assets = []
        } = searchResultsObj[searchType] || {}
        const combined = [
          ...results?.map((item) => ({ ...item, _origin: 'resultsets' })),
          ...assets?.map((item) => ({ ...item, _origin: 'resultsets' })),
          ...resumes?.map((item) => ({ ...item, _origin: 'resumesets' })),
          ...resumesets?.map((item) => ({
            ...item,
            _origin: 'resumesets_new'
          })),
          ...resultsets?.map((item) => ({ ...item, _origin: 'resultsets_new' }))
        ]
        const seenIds = new Map()
        const filteredCombined = combined.filter((item) => {
          const id = item.es_id
          if (!seenIds.has(id)) {
            seenIds.set(id, true)
            return true
          }
          return false
        })
        const filteredResultsets = filteredCombined
          .filter((item) =>
            ['resultsets_new', 'resultsets'].includes(item._origin)
          )
          .map(({ _origin, ...rest }) => rest)
        const filteredResumeSets = filteredCombined
          .filter((item) =>
            ['resumesets_new', 'resumesets'].includes(item._origin)
          )
          .map(({ _origin, ...rest }) => rest)
        const imageFilterData = _.filter(
          filteredResultsets,
          (fil) =>
            ['image', 'video'].includes(fil?.result_type) &&
            fil?.aspectRatio &&
            fil?.aspectRatio !== undefined
        )
        const imageAspectRatioArray = imageFilterData.map(
          (asset) => asset?.aspectRatio
        )
        const otherData = _.filter(
          filteredResultsets,
          (fil) => !['image', 'video'].includes(fil?.result_type)
        )
        otherData.forEach((result, index) => {
          const { result_type = '', addedScreenShot, addedVariation } = result
          const displayTag = findDocumentTypeTag(result)
          otherData[index].displayTag = displayTag
          if (result_type === 'section' && !addedScreenShot) {
            otherData[index].addedScreenShot = true
            addScreenshotsToSections(
              result,
              index,
              s3Obj,
              domain_id,
              setSearchResultsObj,
              searchType
            )
          } else if (result_type === 'narrative' && !addedVariation) {
            otherData[index].addedVariation = true
            addVariationsToKnowledges(
              result,
              index,
              domain_id,
              setSearchResultsObj,
              abortController,
              searchType
            )
          }
        })
        const allcount =
          filteredResumeSets?.length +
          otherData?.length +
          imageFilterData?.length
        let queryDone = false
        if (allcount >= count) {
          queryDone = true
        }
        const data = {
          assets: [...imageFilterData],
          assetsAspectRatio: [...imageAspectRatioArray],
          results: [...otherData],
          resumes: [...filteredResumeSets],
          keyword: searchKey,
          allcount,
          page,
          queryDone
        }
        setSearchResultsObj((prev) => ({ ...prev, [searchType]: data }))
        resolve(data)
      } catch (error) {
        console.log(error)
        reject(null)
      }
    })
  }

  const removeDuplicatesAll = (processedData, count) => {
    return new Promise((resolve, reject) => {
      try {
        const {
          resultsets,
          resumesets,
          duplicates_hidden = 0,
          filterMeta = {}
        } = processedData

        processFilterMeta(filterMeta)
        let hidden = duplicates_hidden
        const similar_results = []
        const duplicateIds = new Set()
        const resultsetsProcessed = resultsets.reduce((acc, obj1) => {
          const matchingArrs = similar_results.filter((similar_results) =>
            similar_results.includes(obj1.content_id)
          )
          if (matchingArrs.length > 0) {
            const duplicates = matchingArrs.reduce(
              (duplicates, matchingArr) => {
                const matchingObjs = matchingArr
                  .map((matchingId) =>
                    resultsets.find((obj) => obj.content_id === matchingId)
                  )
                  .filter(
                    (matchingObj) =>
                      matchingObj && matchingObj.content_id !== obj1.content_id
                  )
                return duplicates.concat(matchingObjs)
              },
              []
            )
            const filtered = acc.filter(
              ({ content_id }) =>
                !duplicates.some(({ content_id: id }) => id === content_id)
            )
            filtered.push({
              ...obj1,
              variationsInResult: duplicates
            })
            duplicates.forEach((duplicate) => {
              duplicateIds.add(duplicate.content_id)
              hidden += 1
            })
            return filtered
          } else {
            if (!duplicateIds.has(obj1.content_id)) {
              acc.push(obj1)
            }
            return acc
          }
        }, [])
        const imageFilterData = _.filter(
          resultsetsProcessed,
          (fil) =>
            ['image', 'video'].includes(fil?.result_type) &&
            fil?.aspectRatio &&
            fil?.aspectRatio !== undefined
        )
        const imageAspectRatioArray = imageFilterData.map(
          (asset) => asset?.aspectRatio
        )
        const otherData = _.filter(
          resultsetsProcessed,
          (fil) => !['image', 'video'].includes(fil?.result_type)
        )
        otherData.forEach((result, index) => {
          const { result_type = '', addedScreenShot, addedVariation } = result
          if (result_type === 'section' && !addedScreenShot) {
            const displayTag = findDocumentTypeTag(result)
            otherData[index].displayTag = displayTag
            otherData[index].addedScreenShot = true
            addScreenshotsToSections(
              result,
              index,
              s3Obj,
              domain_id,
              setSearchResultsAll
            )
          } else if (result_type === 'narrative' && !addedVariation) {
            otherData[index].addedVariation = true
            addVariationsToKnowledges(
              result,
              index,
              domain_id,
              setSearchResultsAll,
              abortController
            )
          }
        })
        const allcount = resultsets?.length + resumesets?.length + hidden
        let queryDone = false
        if (allcount >= count) {
          queryDone = true
        }
        const data = {
          assets: imageFilterData,
          assetsAspectRatio: imageAspectRatioArray,
          results: otherData,
          resumes: resumesets,
          keyword: searchKey,
          allcount,
          duplicates_hidden: hidden,
          queryDone
        }
        setSearchResultsAll(data)
        resolve(data)
      } catch (error) {
        console.log(error)
        reject(null)
      }
    })
  }

  const handleKeyDown = (e, val) => {
    if (e.key !== 'Enter') return
    e.preventDefault()
    e.target.blur()
    if (val?.label) {
      setSearchKey(val?.label)
      handleSearch()
      return
    }
    handleSearch()
  }

  const clearControllers = () => {
    setError(null)
    setSearchLoadingPage({})
    setHasMoreData({})
    setFilterMetaData({})
    setSelectedTags(null)
    setSearchResultsAll({})
    resultCountRef.current = {}
    setResultCount({})
    setSearchResultsObj({})
    setSearchResultsAll({})
    setSearchLoadingPage({ all: true })
    setSearchType('all')
    searchTypeRef.current = 'all'
    setScrollTabMap({})
    abortController.current.forEach((controller) => {
      controller.abort()
    })
  }

  const addRecentSearch = (searchKey, searchType) => {
    dispatch({
      type: ADD_RECENT_SEARCH,
      payload: { search_keyword: searchKey, search_type: searchType }
    })
  }

  useEffect(() => {
    if (resultCount && !_.isEmpty(resultCount)) {
      const newTabs = [...tabs]
      newTabs.forEach((tab, index) => {
        const { key, label } = tab
        newTabs[index].label = `${label} (${resultCount[key]})`
      })
      setTabOptions(newTabs)
    }
  }, [resultCount])

  const navigateToPage = () => {
    const searchTypeCurrent = searchTypeRef.current
    if (searchTypeCurrent === 'all') {
      if (!searchLoadingPage?.all && !searchLoading) {
        handleSearchAll(page + 1)
      }
    } else {
      const searchResultsObj = searchResultsObjRef.current
      const currentPage = searchResultsObj[searchTypeCurrent]?.page
        ? searchResultsObj[searchTypeCurrent]?.page + 1
        : 1
      if (!searchLoadingPage?.[searchTypeCurrent]) {
        handleSearchType(searchTypeCurrent, currentPage)
      }
    }
  }

  const handleSearch = async () => {
    handleSearchAll()
  }

  const getAvailableData = (searchResultsAll, searchType) => {
    if (searchType === 'document') {
      const results = searchResultsAll?.results?.filter(
        (result) => result?.result_type === 'section'
      )
      return results
    } else if (searchType === 'knowledge') {
      const results = searchResultsAll?.results?.filter(
        (result) => result?.result_type === 'narrative'
      )
      return results
    } else if (searchType === 'asset') {
      const assets = searchResultsAll?.assets
      const assetsAspectRatio = searchResultsAll?.assetsAspectRatio
      return { assets, assetsAspectRatio }
    } else if (searchType === 'people') {
      const results = searchResultsAll?.resumes
      return results
    } else if (searchType === 'client') {
      const results = searchResultsAll?.results?.filter(
        (result) => result?.result_type === 'client'
      )
      return results
    } else if (searchType === 'project') {
      const results = searchResultsAll?.results?.filter(
        (result) => result?.result_type === 'project'
      )
      return results
    }
  }

  const checkScrollBar = (hasMoreData, data, inPage, currentSearchType) => {
    if (_.isEmpty(selectedTags)) {
      setHasMoreData((prev) => ({ ...prev, [currentSearchType]: hasMoreData }))
      setTimeout(() => {
        const searchTypeCurrent = searchTypeRef.current
        const searchResultsObj = searchResultsObjRef.current
        if (hasMoreData && currentSearchType === searchTypeCurrent) {
          const hasScroll = hasVerticalScrollbar(scrollId)
          if (!hasScroll) {
            if (searchTypeCurrent !== 'all') {
              const currentPage =
                inPage || searchResultsObj[searchTypeCurrent]?.page
                  ? searchResultsObj[searchTypeCurrent]?.page + 1
                  : 1
              handleSearchType(searchTypeCurrent, currentPage)
            } else {
              const currentPage = inPage || page
              const currentData = data || searchResultsAll
              handleSearchAll(currentPage + 1, currentData)
            }
          }
        }
      }, 1000)
    }
  }

  const handleSearchType = async (search_type, new_page = 1) => {
    if (new_page && search_type) {
      try {
        setSearchLoadingPage({ ...searchLoadingPage, [search_type]: true })
        const req = {
          search_type,
          search_keyword: searchKey,
          page_num: new_page,
          page_size: 50
        }
        const newController = new AbortController()
        const resultCountObj = resultCountRef.current
        abortController.current = [...abortController.current, newController]
        const response = await searchApi(req, { signal: newController.signal })
        if (response.status === 200) {
          if (
            response.data?.resultsets?.length === 0 &&
            response.data?.resumeData?.length === 0
          ) {
            setHasMoreData((prev) => ({ ...prev, [search_type]: false }))
            const currentData = searchResultsObjRef.current[search_type]
            setSearchResultsObj({
              ...searchResultsObjRef.current,
              [search_type]: { ...currentData, queryDone: true }
            })
          } else {
            const data = await processSearchData(
              new_page,
              response.data,
              s3Obj,
              searchResultsObjRef,
              searchTypeRef
            )
            const removedData = await removeDuplicates(
              data,
              new_page,
              resultCountObj?.[searchTypeRef.current]
            )
            const { queryDone } = removedData
            if (queryDone) {
              setHasMoreData((prev) => ({ ...prev, [search_type]: false }))
            } else {
              checkScrollBar(true, removedData, new_page, searchTypeRef.current)
              increaseDataLength()
            }
          }
          if (isDashboard && searchType === 'asset') {
            trackEvent(
              mixpanelEvents.DASHBOARD_SEARCH_ASSETS,
              'SUCCESS',
              {},
              req
            )
          }
          setSearchLoadingPage({ ...searchLoadingPage, [search_type]: false })
        } else if (response?.code === 'ERR_CANCELED') {
        } else {
          const apiError = response?.response?.data?.message
            ? response.response.data.message
            : 'Something went wrong. Try Again!'
          setError(apiError)
          if (isDashboard && searchType === 'asset') {
            trackEvent(
              mixpanelEvents.DASHBOARD_SEARCH_ASSETS,
              'FAILED',
              {},
              req
            )
          }
          setSearchLoadingPage({ ...searchLoadingPage, [search_type]: false })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleSearchAll = async (new_page = 1) => {
    setPage(new_page)
    if (new_page === 1) {
      if (loadedInitalQuery) {
        setRedirectTab(searchType)
        redirectTabRef.current = searchType
      }
      setTabOptions(tabs)
      setSearchLoading(true)
      clearControllers()
    } else {
      setSearchLoadingPage({ ...searchLoadingPage, all: true })
    }
    if (searchKey) {
      addSearchParams()
      addRecentSearch(
        searchKey,
        redirectTabRef.current === 'asset' ? 'asset' : 'all'
      )
      const req = {
        search_type: 'all',
        search_keyword: searchKey,
        page_num: new_page,
        page_size: 50
      }
      const newController = new AbortController()
      let resultCountObj = resultCountRef.current
      abortController.current = [...abortController.current, newController]
      const response = await searchApi(req, { signal: newController.signal })
      if (response.status === 200) {
        const { count: resCount } = response.data
        if (new_page === 1) {
          resultCountRef.current = resCount
          setResultCount(resCount)
          resultCountObj = resCount
        }
        if (
          response.data?.resultsets?.length === 0 &&
          response.data?.resumeData?.length === 0
        ) {
          setHasMoreData((prev) => ({ ...prev, all: false }))
          const currentData = searchResultsAllRef.current
          setSearchResultsAll({ ...currentData, queryDone: true })
        } else {
          const data = await processSearchData(
            new_page,
            response.data,
            s3Obj,
            searchResultsAllRef,
            searchTypeRef
          )
          const removedData = await removeDuplicatesAll(
            data,
            resultCountObj?.all
          )
          const { queryDone } = removedData
          if (queryDone) {
            setHasMoreData((prev) => ({ ...prev, all: false }))
          } else {
            checkScrollBar(true, removedData, new_page, 'all')
            increaseDataLength()
          }
        }
        if (isDashboard) {
          trackEvent(mixpanelEvents.DASHBOARD_SEARCH_TEXT, 'SUCCESS', {}, req)
        }
        if (new_page === 1) {
          setTimeout(() => {
            handleChangeTab(redirectTabRef.current)
            setSearchLoading(false)
          }, 1000)
        } else {
          setSearchLoading(false)
        }
        setSearchLoadingPage({ ...searchLoadingPage, all: false })
      } else if (response?.code === 'ERR_CANCELED') {
        return
      } else {
        const apiError = response?.response?.data?.message
          ? response.response.data.message
          : 'Something went wrong. Try Again!'
        setError(apiError)
        if (isDashboard) {
          trackEvent(mixpanelEvents.DASHBOARD_SEARCH_TEXT, 'FAILED', {}, req)
        }
        setSearchLoading(false)
        if (new_page === 1) {
          handleChangeTab(redirectTabRef.current)
          setSearchLoading(false)
        } else {
          setSearchLoading(false)
        }
        setSearchLoadingPage({ ...searchLoadingPage, all: false })
      }
      setLoadedInitalQuery(true)
    }
  }

  const scrollToSavedPosition = (searchType) => {
    const scroll = scrollTabMap[searchType]
    if (scroll) {
      const value = scroll?.value || 0
      window.scrollTo({ top: value, behavior: 'smooth' })
      setScrollTabMap((prev) => ({
        ...prev,
        [searchType]: {
          value: 0,
          toScroll: false
        }
      }))
    }
  }

  const changeTab = (e) => {
    const currentTab = tabOptions[activeTab]
    const currentTabType = currentTab?.key
    if (window.scrollY > 0 && currentTabType) {
      setScrollTabMap((prev) => ({
        ...prev,
        [currentTabType]: {
          value: window.scrollY,
          toScroll: true
        }
      }))
    }
    window.scrollTo(0, 0)
    const tab = tabOptions[e]
    const type = tab.key
    setActiveTab(e)
    setSearchType(type)
    setError(null)
    setDataLength(0)
    const resultCount = resultCountRef?.current[type]
    const searchResultsObj = searchResultsObjRef.current
    const searchResultsAll = searchResultsAllRef.current
    if (e > 0) {
      setSearchLoading(true)
      setHasMoreData((prev) => ({ ...prev, [type]: true }))
      const searchType = type
      const is_exist = resultCount > 0

      if (is_exist) {
        if (type === 'asset') {
          let existingData = searchResultsObj[searchType]?.asset
          const queryDone = searchResultsObj[searchType]?.queryDone
          if (queryDone) {
            setHasMoreData((prev) => ({ ...prev, [type]: false }))
          } else {
            if (!existingData) {
              const data = getAvailableData(searchResultsAll, searchType)
              existingData = data.assets
              setSearchResultsObj((prev) => ({
                ...prev,
                [searchType]: {
                  ...data
                }
              }))
            }
            if (existingData?.length < resultCount) {
              checkScrollBar(true, existingData, null, searchType)
            } else {
              setHasMoreData((prev) => ({ ...prev, [type]: false }))
            }
          }
        } else if (type === 'people') {
          let existingData = searchResultsObj[searchType]?.resumes
          const queryDone = searchResultsObj[searchType]?.queryDone
          if (queryDone) {
            setHasMoreData((prev) => ({ ...prev, [type]: false }))
          } else {
            if (!existingData) {
              existingData = getAvailableData(searchResultsAll, searchType)
              setSearchResultsObj((prev) => ({
                ...prev,
                [searchType]: { resumes: existingData }
              }))
            }
            if (existingData?.length < resultCount) {
              checkScrollBar(true, existingData, null, searchType)
            } else {
              setHasMoreData((prev) => ({ ...prev, [type]: false }))
            }
          }
        } else {
          let existingData = searchResultsObj[searchType]?.results
          const queryDone = searchResultsObj[searchType]?.queryDone
          if (queryDone) {
            setHasMoreData((prev) => ({ ...prev, [type]: false }))
          } else {
            if (!existingData) {
              existingData = getAvailableData(searchResultsAll, searchType)
              setSearchResultsObj((prev) => ({
                ...prev,
                [searchType]: { results: existingData }
              }))
            }
            if (existingData.length < resultCount) {
              checkScrollBar(true, existingData, null, searchType)
            } else {
              setHasMoreData((prev) => ({ ...prev, [type]: false }))
            }
          }
        }
        setSearchLoading(false)
      } else {
        setSearchLoading(false)
        setError('No data found')
      }
    } else if (e === 0) {
      setHasMoreData((prev) => ({ ...prev, [type]: true }))
      const count = resultCountRef?.current.all
      const { allcount } = searchResultsAll
      if (count < allcount) {
        checkScrollBar(true, searchResultsAll, page, 'all')
      } else {
        setHasMoreData((prev) => ({ ...prev, [type]: false }))
      }
    }
  }

  const handleChangeTab = (e, i) => {
    const index = tabOptions.findIndex((tab) => tab.key === e)
    if (index !== -1) {
      changeTab(index)
    }
  }

  const handleFilterByProposal = (result) => {
    const { proposal_id, proposal_filename } = result
    if (!proposal_id || !proposal_filename) return
    const currentFilter = selectedTags?.source_document?.values || []
    if (currentFilter.find((item) => item.value === proposal_id)) {
    } else {
      const newFilter = [
        ...currentFilter,
        { value: proposal_id, label: proposal_filename }
      ]
      setSelectedTags({
        ...selectedTags,
        source_document: {
          values: newFilter
        }
      })
    }
  }

  const { selectedColor } = useColor()

  const bricks = (array, filteredResults, resultKeyword) => {
    return array.map((item, index) => (
      <Result
        assetStyleProps={item}
        res={filteredResults[index]}
        index={index}
        keyword={resultKeyword}
        key={index}
        s3Obj={s3Obj}
        result_type={filteredResults[index]?.result_type ?? ''}
        overWriteAsset
        disableEdit={isAnnotator}
        showTempDataTag={isSuperUser || isAnnotator}
        handleShowImage={handleImageOpenInGallery}
        setSelectedFile={setSelectedFile}
        selectedFile={selectedFile}
        handleCopy={handleCopy}
        src={parent}
        handleFilterByProposal={handleFilterByProposal}
      />
    ))
  }

  const handleCopy = async (content = '', title = '') => {
    trackEvent(
      mixpanelEvents.DASHBOARD_RESULT_COPIED,
      'SUCCESS',
      {},
      { search_key: keyword }
    )
    const newContent = `<html><body><b>${title}</b><br>${content}</body></html>`
    copyHTMLContent(newContent)
  }

  const handleImageOpenInGallery = async (screenshots, index) => {
    const { keyword } = searchData || {}
    await Promise.all(
      screenshots.map(async (img, index) => {
        const signedSrc = await checkExpiry(img.src, s3Obj)
        screenshots[index].src = signedSrc
      })
    )
    setImageGallery(screenshots)
    setImageGalleryIndex(index)
    trackEvent(
      mixpanelEvents.DASHBOARD_SCREENSHOT_OPENED,
      'SUCCESS',
      {},
      { search_key: keyword }
    )
  }

  const handlePersonSelect = async (person) => {
    const file = {
      id: person?.id,
      file_name: person?.name,
      other_data: {
        resumes: [person]
      },
      file_type: 'people'
    }
    setSelectedFile(file)
  }

  const checkAccess = (res) => {
    if (res?.result_type === 'project' && !processProjects) {
      return false
    }
    if (res?.result_type === 'client' && !processClients) {
      return false
    }
    return true
  }

  const removeContentIdParameterFromUrl = (urlPathname, urlSearch) => {
    const currentPath = urlPathname
    const currentSearchParams = new URLSearchParams(urlSearch)

    currentSearchParams.delete('people')
    currentSearchParams.delete('content_id')

    const newUrl = `${currentPath}?${currentSearchParams.toString()}`

    return newUrl
  }

  const renderResults = () => {
    const {
      results = [],
      assets = [],
      assetsAspectRatio = [],
      keyword: resultKeyword = ''
    } = searchData || {}
    let { resumes = [] } = searchData || {}
    resumes = processPeople ? resumes : []

    if (error) {
      return <Box className={classes.errorContainer}>{error}</Box>
    }
    if (searchType === 'all' || searchType === 'text') {
      return (
        <>
          <ResumeResult
            people={resumes}
            s3Obj={s3Obj}
            handlePersonSelect={handlePersonSelect}
            showAllPeopleDefault={false}
            viewAllPeople={() => handleChangeTab('people')}
            pageType="dashboard"
          />
          {!_.isEmpty(results)
            ? results.map((res, filteredResultsIndex) => {
                return (
                  checkAccess(res) && (
                    <>
                      {['all'].includes(searchType) &&
                        !_.isEmpty(assets) &&
                        ((results.length > 3 && filteredResultsIndex === 3) ||
                          results.length < 3) && (
                          <Box>
                            <Box
                              sx={{
                                marginTop: '15px',
                                fontSize: '18px',
                                fontWeight: '600'
                              }}
                            >
                              Assets for{' '}
                              <Box
                                component={'span'}
                                sx={{ fontStyle: 'italic' }}
                              >
                                {resultKeyword}
                              </Box>
                            </Box>
                            <JustifiedLayout
                              items={assetsAspectRatio}
                              options={{
                                containerPadding: 20,
                                boxSpacing: 10,
                                targetRowHeight: 200,
                                targetRowHeightTolerance: 0.1,
                                maxNumRows: 1
                              }}
                            >
                              {(items) => bricks(items, assets, resultKeyword)}
                            </JustifiedLayout>
                            <Box
                              sx={{
                                marginTop: '5px',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  height: '1px',
                                  backgroundColor: '#ccc'
                                }}
                              />
                              <Button
                                variant="outlined"
                                onClick={() => handleChangeTab('asset')}
                              >
                                View all
                              </Button>
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  height: '1px',
                                  backgroundColor: '#ccc'
                                }}
                              />
                            </Box>
                          </Box>
                        )}
                      <Result
                        res={res}
                        result_type={res?.result_type ?? ''}
                        index={filteredResultsIndex}
                        keyword={resultKeyword}
                        key={filteredResultsIndex}
                        s3Obj={s3Obj}
                        disableEdit={isAnnotator}
                        showTempDataTag={isSuperUser || isAnnotator}
                        handleShowImage={handleImageOpenInGallery}
                        setSelectedFile={setSelectedFile}
                        selectedFile={selectedFile}
                        handleCopy={handleCopy}
                        handleFilterByProposal={handleFilterByProposal}
                        src={parent}
                        documentTagCenter={documentTagCenter}
                      />
                    </>
                  )
                )
              })
            : !_.isEmpty(assets) && (
                <Box>
                  <Box
                    sx={{
                      marginTop: '15px',
                      fontSize: '18px',
                      fontWeight: '600'
                    }}
                  >
                    Assets for{' '}
                    <Box component={'span'} sx={{ fontStyle: 'italic' }}>
                      {resultKeyword}
                    </Box>
                  </Box>
                  <JustifiedLayout
                    items={assetsAspectRatio}
                    options={{
                      containerPadding: 20,
                      boxSpacing: 10,
                      targetRowHeight: 200,
                      targetRowHeightTolerance: 0.1,
                      maxNumRows: 1
                    }}
                  >
                    {(items) => bricks(items, assets, resultKeyword)}
                  </JustifiedLayout>
                  <Box
                    sx={{
                      marginTop: '5px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        height: '1px',
                        backgroundColor: '#ccc'
                      }}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => handleChangeTab('asset')}
                    >
                      View all
                    </Button>
                    <Box
                      sx={{
                        flexGrow: 1,
                        height: '1px',
                        backgroundColor: '#ccc'
                      }}
                    />
                  </Box>
                </Box>
              )}
        </>
      )
    } else if (
      ['document', 'project', 'client', 'knowledge'].includes(searchType)
    ) {
      return (
        <>
          {results.map((res, filteredResultsIndex) => {
            return (
              checkAccess(res) && (
                <>
                  <Result
                    res={res}
                    result_type={res?.result_type ?? ''}
                    index={filteredResultsIndex}
                    keyword={resultKeyword}
                    key={filteredResultsIndex}
                    s3Obj={s3Obj}
                    disableEdit={isAnnotator}
                    showTempDataTag={isSuperUser || isAnnotator}
                    handleShowImage={handleImageOpenInGallery}
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                    handleCopy={handleCopy}
                    src={parent}
                    handleFilterByProposal={handleFilterByProposal}
                    documentTagCenter={documentTagCenter}
                  />
                </>
              )
            )
          })}
        </>
      )
    } else if (searchType === 'asset') {
      return (
        <Box sx={{ minHeight: '100%' }}>
          <JustifiedLayout
            items={assetsAspectRatio}
            options={{
              containerPadding: 20,
              boxSpacing: 10,
              targetRowHeight: 200,
              targetRowHeightTolerance: 0.1
            }}
          >
            {(items) => bricks(items, assets, resultKeyword)}
          </JustifiedLayout>
        </Box>
      )
    } else if (searchType === 'people') {
      return (
        <ResumeResult
          people={resumes}
          s3Obj={s3Obj}
          handlePersonSelect={handlePersonSelect}
          showAllPeopleDefault={true}
          viewAllPeople={() => handleChangeTab('people')}
          pageType="dashboard"
        />
      )
    }
  }

  useEffect(() => {
    const dataToFilter =
      searchType === 'all' ? searchResultsAll : searchResultsObj[searchType]
    const filtered = _.cloneDeep(dataToFilter)

    if (!_.isEmpty(selectedTags)) {
      const { results = [], resumes = [], assets = [] } = dataToFilter || {}
      const filteredResults = results.filter((result) => {
        const { result_type } = result
        return Object.keys(selectedTags || {}).every((key) => {
          const selectedValues = selectedTags[key]?.values || []
          const selectedValuesArray = selectedValues.map((item) => item.value)
          const selectedCondition = selectedTags[key]?.condition || 'is'
          const selectedConditionBool = selectedCondition === 'is'
          if (key.includes('_')) {
            if (
              ['section', 'project'].includes(result_type) &&
              key === 'source_document'
            ) {
              const { proposal_id = '' } = result || {}
              return (
                selectedValuesArray?.includes(proposal_id) ===
                selectedConditionBool
              )
            } else if (
              result_type === 'narrative' &&
              key === 'knowledge_collection'
            ) {
              const { data = {} } = result || {}
              const { collections = {} } = data || {}
              const collectionsArray = Object.keys(collections)
              const someValuesMatchCondition = collectionsArray?.some(
                (collection) =>
                  selectedValuesArray.includes(collection) ===
                  selectedConditionBool
              )
              return someValuesMatchCondition
            } else if (result_type === 'narrative' && key === 'created_by') {
              const { created_by_uuid = '' } = result || {}
              return (
                selectedValuesArray?.includes(created_by_uuid) ===
                selectedConditionBool
              )
            } else {
              return false
            }
          } else {
            const { tag_center = [] } = result || {}
            const someValuesMatchCondition = selectedValuesArray.some(
              (tag) => tag_center?.includes(tag) === selectedConditionBool
            )
            return someValuesMatchCondition
          }
        })
      })
      const filteredAssets = assets.filter((asset) => {
        return Object.keys(selectedTags || {}).every((key) => {
          const selectedValues = selectedTags[key]?.values || []
          const selectedValuesArray = selectedValues.map((item) => item.value)
          const selectedCondition = selectedTags[key]?.condition || 'is'
          const selectedConditionBool = selectedCondition === 'is'

          if (key.includes('_')) {
            if (key === 'asset_tags') {
              const { tags } = asset || {}
              const tagsValue = _.isArray(tags)
                ? tags.map((tag) => tag.values)
                : []
              const someValuesMatchCondition = tagsValue.some(
                (tag) =>
                  selectedValuesArray?.includes(tag) === selectedConditionBool
              )
              return someValuesMatchCondition
            } else if (key === 'created_by') {
              const { uploaded_by_uuid = '' } = asset || {}
              return (
                selectedValuesArray?.includes(uploaded_by_uuid) ===
                selectedConditionBool
              )
            } else if (key === 'asset_type') {
              const { result_type } = asset || {}
              return (
                selectedValuesArray?.includes(result_type) ===
                selectedConditionBool
              )
            } else {
              return false
            }
          } else {
            const { tag_center = [] } = asset
            const someValuesMatchCondition = selectedValuesArray.some(
              (tag) => tag_center?.includes(tag) === selectedConditionBool
            )
            return someValuesMatchCondition
          }
        })
      })
      setSearchData({
        ...filtered,
        results: filteredResults,
        assets: filteredAssets,
        resumes: []
      })
    } else {
      setSearchData(filtered)
    }
  }, [selectedTags, searchType, searchResultsAll, searchResultsObj])

  const hasFilters = !_.isEmpty(selectedTags)

  const onClose = () => {
    setSelectedFile(null)
    const newUrl = removeContentIdParameterFromUrl(
      location.pathname,
      location.search
    )
    navigate(newUrl)
  }

  return (
    <Box
      id={scrollId}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100'
      }}
    >
      <Container
        className={
          isDashboard ? classes.rootContainer : classes.rootContainerFullWidth
        }
      >
        <Box sx={{ flexGrow: '1' }}>
          <Grid
            container
            sx={{
              height: '100%',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Grid
              item
              sx={{
                height: '100vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Box
                id="dashboard-searchbar"
                style={{
                  position: 'sticky',
                  top: 0,
                  background: 'white',
                  zIndex: '1000'
                }}
              >
                <Box id="dashboard-search-input" className={classes.field}>
                  <AutoCompleteSearch
                    s3Obj={s3Obj}
                    setSearchKey={setSearchKey}
                    searchKey={searchKey}
                    handleKeyDown={handleKeyDown}
                    handleSearch={handleSearch}
                    showSearchIcon={false}
                    disabled={searchLoading}
                    initalState={false}
                  />
                  <Button
                    onClick={() => handleSearch()}
                    style={{ background: selectedColor, marginLeft: '10px' }}
                    disabled={searchLoading}
                  >
                    Go
                  </Button>
                </Box>
                <Box id="dashboard-filter">
                  <div
                    id="search-tabs"
                    style={{ position: 'relative', overflow: 'auto' }}
                  >
                    <Tabs
                      isFlex
                      activeTab={activeTab}
                      disabled={searchLoading}
                      sectionOverFlow
                      data={tabOptions}
                      tabChangeCallBack={(e) => changeTab(e)}
                      extraContent={
                        <Box className={classes.tabButton}>
                          {scrollTabMap[searchType]?.toScroll &&
                            !searchLoading && (
                              <Box>
                                <Tooltip title={'Scroll To Previous Position'}>
                                  <IconButton
                                    sx={{ padding: 0 }}
                                    disabled={searchLoading}
                                    onClick={() =>
                                      scrollToSavedPosition(searchType)
                                    }
                                  >
                                    <Box className={classes.filterButton}>
                                      <KeyboardDoubleArrowDownIcon />
                                    </Box>
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            )}
                          <TagFilter
                            showButton
                            showTags={false}
                            tags={{ ...filterMetaData, ...tagsOptions }}
                            disabled={searchLoading}
                            selectedTags={selectedTags}
                            onChange={handleTagsValueChange}
                            filterIcon={
                              _.isEmpty(selectedTags) && (
                                <Tooltip title="Add Filter">
                                  <Box className={classes.filterButton}>
                                    Add Filter
                                  </Box>
                                </Tooltip>
                              )
                            }
                            clearFilter={
                              !_.isEmpty(selectedTags) && (
                                <Tooltip title="Clear Filter">
                                  <IconButton
                                    sx={{ padding: '0px' }}
                                    disabled={searchLoading}
                                    disableRipple
                                    onClick={() => setSelectedTags({})}
                                  >
                                    <Box className={classes.filterButton}>
                                      Clear Filter
                                    </Box>
                                  </IconButton>
                                </Tooltip>
                              )
                            }
                          />
                        </Box>
                      }
                    />
                  </div>
                  {!searchLoading && (
                    <Box className={classes.filterContainer}>
                      <TagFilter
                        showButton={false}
                        showTags
                        tags={{ ...filterMetaData, ...tagsOptions }}
                        disabled={searchLoading}
                        selectedTags={selectedTags}
                        onChange={handleTagsValueChange}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              {searchLoading ? (
                <Box className={classes.loaderContainer}>
                  <Loader
                    loading={searchLoading}
                    caption={'loading your search'}
                    flex
                  />
                </Box>
              ) : (
                <Box
                  id="scrollableDiv"
                  sx={{
                    height: '100%',
                    width: '100%',
                    overflow: 'auto',
                    paddingRight: '5px'
                  }}
                >
                  <InfiniteScroll
                    onScroll={() => {
                      if (window.scrollY > 0) {
                        setScrollTabMap((prev) => ({
                          ...prev,
                          [searchType]: {
                            value: window.scrollY,
                            toScroll: false
                          }
                        }))
                      }
                    }}
                    dataLength={dataLength}
                    next={() => navigateToPage()}
                    endMessage={
                      !_.isEmpty(searchData) && (
                        <Box className={classes.errorContainer}>
                          {hasFilters ? (
                            searchData?.queryDone ? (
                              <Box>End of Results</Box>
                            ) : (
                              <>
                                <Box>
                                  Clear applied filters to load more data
                                </Box>
                                <Box className={classes.clearButton}>
                                  <Box
                                    className={classes.filterButton}
                                    onClick={() => setSelectedTags({})}
                                  >
                                    Clear Filter
                                  </Box>
                                </Box>
                              </>
                            )
                          ) : (
                            <Box>End of Results</Box>
                          )}
                        </Box>
                      )
                    }
                    hasMore={
                      hasFilters ? false : hasMoreData?.[searchType] || false
                    }
                    loader={
                      searchLoadingPage?.[searchType] && (
                        <Loader
                          loading={searchLoadingPage[searchType]}
                          caption={''}
                          flex
                        />
                      )
                    }
                  >
                    <Box
                      sx={{
                        flexGrow: '1',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Box className={classes.resultWrapper}>
                        {renderResults()}
                      </Box>
                    </Box>
                  </InfiniteScroll>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
        <Dialog
          fullScreen
          sx={{ margin: '5vh' }}
          disableEnforceFocus={true}
          open={!!selectedFile}
          onClose={onClose}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }
          }}
        >
          <Box className={classes.fileViwerWrapper}>
            <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
              <ResourceFileView
                s3Obj={s3Obj}
                file={selectedFile}
                setFile={setSelectedFile}
                onClose={onClose}
                isSuperUser={isSuperUser}
                isAdmin={isAdmin}
                setImageGallery={setImageGallery}
                resourceLoading={resourceLoading}
                onDeleteCallback={(e) => {
                  console.log(e)
                }}
                onUpdateCallback={(e) => {
                  console.log(e)
                }}
              />
            </Box>
          </Box>
        </Dialog>
        {imageGallery.length > 0 && (
          <ImageGallery
            images={imageGallery}
            onClose={() => setImageGallery([])}
            options={{ initialViewIndex: imageGalleryIndex }}
          />
        )}
      </Container>
    </Box>
  )
}
export default DashboardResult
